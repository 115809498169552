.faq {
   position: relative;
   text-align: center;
   background-color: #ffbc9d;
}

.faq::before {
content: '';
position: absolute;
background-image: url(../images/faq-top.webp);
background-repeat: no-repeat;
background-size: cover;
top: -260px;
left: 0;
width: 100%;
height: 300px;
}
.faq::after {
   content: '';
   position: absolute;
   background-image: url(../images/faq-bottom.webp);
   background-repeat: no-repeat;
   background-size: cover;
   bottom: -406px;
   left: 0;
   width: 100%;
   height: 407px;
   }

   .faq__title {
      position: relative;
      margin-bottom: 125px;
   }

   .faq__title::before {
      content: url(../images/wavy-line-orange.svg);
      position: absolute;
      bottom: -25px;
      left: 45%;
   }

   .faq__title::after {
      content: url(../images/white-yellow.svg);
      position: absolute;
      bottom: -25px;
      left: 20%;
   }

   .faq__content {
      position: relative;
   }

   .faq__content::after {
      content: url(../images/orange-triangle.svg);
      position: absolute;
      top: -160px;
      right: 312px;
   }

   
   .faq__content::before {
      content: url(../images/yellow-triangle.svg);
      position: absolute;
      bottom: -140px;
      right: 200px;
      z-index: 10;
   }
   

.faq__accordion {
   position: relative;
   cursor: pointer;
   padding: 18px;
   width: 100%;
   text-align: left;
   outline: none;
   font-weight: 700;
font-size: 25px;
letter-spacing: 0.02em;
color: var(--black);
transition:  1s ease;
}

.faq__panel-item {
   position: relative;
   margin-top: 8px;
   margin-bottom: 25px;
   padding-left: 35px;
   font-weight: 400;
font-size: 20px;
line-height: 130%;
letter-spacing: 0.02em;
color: var(--black);
}
.faq__panel-item-small {
   padding-left: 35px;
   font-weight: 400;
font-size: 15px;
line-height: 130%;
letter-spacing: 0.02em;
color: var(--black);
}

.faq__panel-item::before {
   content:'' ;
   position: absolute;
   background-image: url(../images/education-vector.svg);
   width: 24px;
   height: 25px;
   left: 0;
   bottom: 0;
   top: 0;
   margin: auto;
}

.faq__panel {
   padding: 0 18px;
   max-width: 1050px;
   background-color: transparent;
   max-height: 0;
   overflow: hidden;
   text-align: left;
   transition: .3s ease;
  
}

.faq__accordion-outline {
   padding: 12px;
   margin: 0 auto;
   margin-bottom: 20px;
   border: 5px solid var(--main-color);
   border-radius: 25px;
   max-width: 1260px;
}

.faq__accordion::before,
.faq__accordion::after {
   content: '';
   width: 20px;
   height: 2px;
   background-color: var(--main-color);
   position: absolute;
   right: 23px;
   top: 32px;
   transition: transform .3s ease-in-out 0s;
}

.faq__accordion::after {
   transform: rotate(-90deg);
}

.faq__accordion._active::after {
   transform: rotate(0deg);
}

.visible {
   visibility: visible;
}

.hidden {
   visibility: hidden;
}

.btn-buy-certificate {
   cursor: pointer;
}

@media (max-width: 1023px) {
   .faq__title {
      margin-bottom: 70px;
   }
   .faq__title::before {
      transform: scale(0.8);
      left: 40%;
   }
   .faq__title::after {
      transform: scale(0.8);
   }
   .faq__content::after {
      transform: scale(0.8);
      right: 150px;
      top: -95px;
   }
   .faq {
      padding-right: 50px;
      padding-left: 50px;
   }
   .faq__accordion {
      font-size: 20px;

   }

   .faq__accordion-title {
      max-width: 440px;
   }
   .faq__panel-item {
      font-size: 16px;
   }
   .faq__panel-item-small {
      font-size: 13px;
   }
   .faq__panel {
      max-width: 550px;
   }
}

@media (max-width: 767px) {
   .faq {
      padding-right: 10px;
      padding-left: 10px;
   }
   .faq::before {
     height: 150px;
     top: -149px;
   }
   .faq::after {
      height: 150px;
      bottom: -149px;
   }
   .faq__accordion-outline {
      border: 2px solid var(--main-color);
   }
   .faq__accordion-title {
max-width: 280px;
   }
   .faq__accordion {
      font-size: 16px;
   }
   .faq__panel-item {
      font-size: 14px;
   }
   .faq__panel-item-small {
      font-size: 12px;
   }
   .faq__panel {
      max-width: 270px;
   }

   .faq__title::before {
      transform: scale(0.5);
      left: 27%;
      bottom: -20px;
   }
   .faq__title::after {
      transform: scale(0.5);
      bottom: -100px;
      left: 7%;
   }
   .faq__content::after {
      transform: scale(0.5);
      right: 50px;
   }
   .faq__content::before {
      transform: scale(0.5);
   }
}

@media (max-width: 400px) {
   .faq__accordion-title {
      max-width: 180px;
   }
}
