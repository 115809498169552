.tickets {
   padding-bottom: 206px;
   text-align: center;
}

.tickets__title {
   position: relative;
   margin-bottom: 138px;
}

.tickets__title::after {
   content: url(../images/wavy-line-orange.svg);
   position: absolute;
   bottom: -30px;
   right: 32%;
}

.tickets__title::before {
   content: url(../images/yellow-red-2.svg);
   position: absolute;
   bottom: -80px;
   left: 13%;
}

.tickets__subtitle {
   position: relative;
   font-weight: 800;
   font-size: 35px;
   line-height: 131%;
   letter-spacing: 0.02em;
   color: var(--black);
   margin:30px auto;
}

.tickets__subtitle::before {
   content: url(../images/red-blue-green.svg);
   position: absolute;
   bottom: -127px;
   left: 120px;
} 

.tickets__subtitle::after {
   content: url(../images/green-triangle.svg);
   position: absolute;
   bottom: 100px ;
       right: 160px;
} 

.tickets__description {
   max-width: 800px;
   margin:auto;
   margin-bottom: 100px;
   font-weight: 400;
font-size: 25px;
line-height: 131%;
letter-spacing: 0.02em;
text-align: center;
color: var(--black);
}

.tickets__tel {
   font-weight: 800;
}

.tickets__cards {
   position: relative;
   margin: 0 auto;
   max-width: 1000px;
   display: grid;
   grid-template-columns: repeat(4, 270px);
   justify-content: center;
   gap: 30px;
}

.tickets__cards::before {
   content: url(../images/ray-bottom-green.svg);
   position: absolute;
   left: -100px;
   bottom: 0;
}

.tickets__cards::after {
   content: url(../images/ray-top-red.svg);
   position: absolute;
   right: -67px;
   top: -50px;
}

.tickets__number{
   padding-top: 20px;
   text-align: center;
   background-image: url(../images/green.svg);
   background-position: top;
   height: 105px;
   background-repeat: no-repeat;
   font-size: 45px;
   letter-spacing: 0.02em;
   text-transform: uppercase;
   color: var(--white);
   font-weight: 900;
}

.tickets__descr {
   padding: 20px;
   font-weight: 300;
font-size: 16px;
line-height: 130%;
letter-spacing: 0.02em;
color: var(--black);
}

.tickets__svg::before {
   content: url(../images/blue-red-yellow.svg);
   position: absolute;
   right: 0px;
   top: 0px;
   transform: scale(0.5);
   display: none;
}
   .tickets__svg::after  {
      content: url(../images/blue-red-yellow.svg);
      position: absolute;
      left: 0px;
      bottom: 0px;
      transform: scale(0.5);
   display: none;

   }

   .tickets__svg1::before {
      content: url(../images/green-triangle.svg);
      position: absolute;
      right: 0px;
      bottom: 0px;
      transform: scale(0.5);
   display: none;

   }
      .tickets__svg1::after  {
         content: url(../images/green-triangle.svg);
         position: absolute;
         right: 0px;
         top: 0px;
         transform: scale(0.5);
   display: none;

      }



@media (max-width: 1024px) {
   .tickets__cards {
      grid-template-columns: repeat(2, 270px);
      gap:0;
   }

   .tickets__subtitle {
font-size: 30px;
max-width: 500px;

   }

   .tickets__description {
font-size: 22px;
max-width: 500px;
margin-bottom: 60px;
   }

   .tickets__cards::after {
      content: url(../images/ray-top-red.svg);
      position: absolute;
      right: 20%;
      top: -50px;
   }

   .tickets__cards::before {
      left: 20%;
      bottom: -9%;
   }

.tickets__title {
   margin-bottom: 80px;
}

.tickets__title::before {
   transform: scale(0.6);
}

.tickets__subtitle::after {
   transform: scale(0.6);
   bottom: 57px;
}
.tickets__subtitle::before{
   transform: scale(0.6);
   left: -50px;
   bottom: -300px;
}

.tickets__title::after {
   transform: scale(0.8);
   right: 27%;
}

.tickets__cards::after,
.tickets__cards::before{
   transform: scale(0.8);
}
}

@media (max-width: 768px) {
   .tickets{ 
      padding-bottom: 220px;
   }
   .tickets__cards::before {
   bottom: 0;
   left: 13%;
   } 
   .tickets__title::after {
      right: 20%;
   }
}

@media (max-width: 767px) {
   .tickets {
      padding-bottom: 170px;
   }

   .tickets__title {
      margin-bottom: 70px;
   }
   .tickets__subtitle {
      font-size: 20px;
      max-width: 250px;
   }

   .tickets__svg::before,
   .tickets__svg::after {
      display: block;
   }

   .tickets__svg1::before,
   .tickets__svg1::after {
      display: block;
   }

   .tickets__title::after {
      bottom: -17px;
      right: 9%;
      transform: scale(0.5);
   }

   .tickets__description {
      font-size: 16px;
     max-width: 200px;
   }

   .tickets__descr {
      font-size: 14px;
   }

   .tickets__cards::after {
      right: 11%;
      transform: scale(0.5);
  }

  .tickets__cards::before {
   left: 7%;
   transform: scale(0.5);
}

.tickets__number {
   transform: scale(0.8);
}

   .tickets__title {
      margin-bottom: 40px;
   }

   .tickets__description {
      margin-bottom: 40px;
   }
   .tickets__cards {
      grid-template-columns: repeat(1,270px);
      gap: 20px;
  }

  .tickets__subtitle::after {
   bottom: -90px;
   right: 242px;
  }

  .tickets__title::before {
   transform: scale(0.5);
   left: 2%;
  }
  .tickets__svg::before {
   top: 60%;
  }
  .tickets__svg::after {
   bottom: 30%;
  }

  .tickets__svg1::after {
   top: 30%;
  }
  .tickets__cards {
   gap: 0;
  }
}
