.footer {
   padding-top: 250px;
   padding-bottom: 100px;
   text-align: center;
   background-image: url(../images/footer-bg.svg);
   background-repeat: no-repeat;
   background-size: cover;
}

.footer__title {
   position: relative;
   margin-bottom: 140px;
}

.footer__title::after {
   content: url(../images/wavy-line-white.svg);
   position: absolute;
   bottom: -30px;
   right: 44%;
}

.footer__title::before {
   content: url(../images/red-yellow-white.svg);
   position: absolute;
   top: -110px;
   left: 70px;
}

.footer__content {
   position: relative;
   text-align: start;
   justify-content: space-around;
}

.footer__left {
border: 3.6125941276550293px solid var(--main-color);
border-radius: 30px;
width: 619px;
height: 358px;
z-index: 10;
overflow: hidden;
}

.footer__content::before {
   content: url(../images/dotted-line.svg);
   position: absolute;
   left: -16px;
   top: -114px 
}

.footer__content::after {
   content: url(../images/ray-bottom.svg);
   position: absolute;
   left: 95px;
    bottom: -60px;
}

.footer__svg {
   margin-bottom: 23px;
}

.footer__subtitle {
   position: relative;
   margin-bottom: 25px;
   font-weight: 800;
font-size: 25px;
letter-spacing: 0.02em;
color: #000;
}

.footer__subtitle::after{
   content: url(../images/white-red.svg);
   position: absolute;
   top: -167px;
}


.footer__address {
   margin-bottom: 65px;
   position: relative;
   max-width: 250px;
   font-weight: 300;
font-size: 20px;
line-height: 130%;
letter-spacing: 0.02em;
}


.footer__slider {
  margin: 0 auto;
  overflow: hidden;
}

.slider {
   position: relative;
   display: flex;
   transition: transform 0.5s ease-in-out;
 }

.prev-button,
.next-button {
  position: absolute;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  color: white;
}

.prev-button {
   left: 70%;
   top: 33%;
  cursor: pointer;
}

.next-button {
   top: 33%;
   right: 8%;
  cursor: pointer;
}

.footer__info {
   font-weight: 400;
font-size: 22px;
letter-spacing: 0.02em;
color: var(--black);
}

.footer__tel {
   margin-bottom: 13px;
   font-weight: 800;
font-size: 25px;
letter-spacing: 0.02em;
color: var(--black);
}

.footer__text {
   margin-bottom: 30px;
}

.footer__messengers {
   position: relative;
   gap: 15px;
}

.footer__messengers::before{
   content: url(../images/footer-carrot.svg);
   position: absolute;
   left: -350px;
   bottom: -15px;
   z-index: 10;
}

.footer__messengers::after{
   content: url(../images/white-triangle.svg);
   position: absolute;
   bottom: -35px;
}

.footer__information {
   margin-top: 100px;
   justify-content: space-around;
}

.footer__confidentiality {
   font-weight: 800;
font-size: 22px;
letter-spacing: 0.02em;
color: var(--main-color);
cursor: pointer;
}

.footer__messengers-tg:hover {
opacity: 0.8;
transition: opacity 0.5s;
}
.footer__messengers-vk:hover {
   opacity: 0.8;
   transition: opacity 0.5s;
}

@media (max-width: 1366px) {
   .footer__messengers::before {
      left: -285px;
   }
   .next-button {
      right: 6%;
   }
}
@media (max-width: 1024px) {
   .next-button {
      right: 0;
   }

   .prev-button {
      left: 68%;
   }
   .footer__messengers::before {
      left: -113px;
   }
   .footer__title::before,
   .footer__subtitle::after,
   .footer__content::before,
   .footer__messengers::after {
      transform: scale(0.8);
   }
   .footer__content::before{

      left: -116px;
       top: -130px;
   }
}

@media (max-width: 1023px) {
   .footer {

      padding-bottom: 65px;
   }
   .footer__title {
      margin-bottom: 50px;
   }
   .footer__content{
      flex-direction: column;
   }
   .footer__left {
      margin: 0 auto;
      margin-bottom: 30px;
   }
   .footer__right {
      padding-left: 100px;
      margin-top: 30px;
   }
   .prev-button {
      left: 9%;
      top: 67%;
   }
   .next-button {
      right: 49%;
      top: 67%;
   }

   .footer__messengers::before {
      left: 84%;
      bottom: 375px;
   }
   .footer__information {
      padding-left: 100px;
      flex-direction: column;
      text-align: start;
   }
   .footer__info,
   .footer__confidentiality {
      font-size: 16px;
   }
   .footer__messengers::after {
      transform: scale(0.5);
      right: 12%;
   }
   .footer__content::after {
      transform: scale(0.5);
      left: 28px;
      bottom: 358px;
   }
   .footer__subtitle::after {
      transform: scale(0.5);
top: -20px;
right: 30%;
   }
}


@media (max-width: 768px) {
   .footer__title::after {
      bottom: 25px;
    right: 30%;
}
.footer__messengers::before {
   bottom: 390px;
}
   }


@media (max-width: 767px) {
   .footer {
      padding-top: 130px;
   }
   .footer__title {
      max-width: 250px;
      margin: 50px auto;
   }

   .footer__left {
      width: 267px;
      height: 155px;
      border-radius: 12px;
   }

   .footer__right {
      margin-top: 0;
   }

   .footer__content::before {
      transform: scale(0.3);
      left: -190px;
    top: -207px;
   }
   .footer__messengers::before {
      transform: scale(0.5);
      left: 61%;
      bottom: 275px;
   }
   .footer__svg {
      max-height: 32px;
   }
   .prev-button {
      left: 15%;
      top: 58%;
   }
   .next-button {
      right: 2%;
      top: 58%;
   }
   .footer__subtitle::after {
      top: 234px;
   }

   .footer__information {
      padding-left: 20px;
     
   }
   .footer__info,
   .footer__confidentiality {
      font-size: 12px;
      max-width: 300px;
   }
   .footer__address {
      margin-bottom: 30px;
      font-size: 14px;
      max-width: 170px;
   }
   .footer__subtitle,
   .footer__tel {
      font-size: 16px;
   }
   .footer__text {
      font-size: 14px;
   }
   .footer__messengers img {
      width: 33px;
   }

   .footer__title::after {
      transform: scale(0.5);
      bottom: 10px;
      right: 7%;
   }
}
