.popup__schedule {
   position: fixed;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0 , 0, 0.8);
   top: 0;
   left: 0;
   z-index: 10;
}

.popup__body{
   min-height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
}

.popup__schedule-card {
   display: flex;
   flex-direction: column;
   align-items: center;
   padding-left: 30px;
   padding-right: 30px;
   border-radius: 47px;
   border: 3px solid var(--main-color);
background: #fad7c7;
}

.popup__schedule-date{
   font-size: 64px;
   color: var(--main-color);
   font-weight: 900;
   text-transform: uppercase;
}

.popup__schedule-item {
   padding:0px 10px;
   font-size: 40px;
   font-weight: 600;
   color: var(--main-color);
   border: 2px solid var(--main-color);
   border-radius: 12px;
}

/* _______выбор города______ */

.popup__city {
   position: fixed;
   width: 100%;
   height: 100%;
   backdrop-filter: blur(5px);
   top: 0;
   left: 0;
   opacity: 0;
   visibility: hidden;
   transition: all .8s;
   z-index: 100;
}
.popup__city.open {
   opacity: 1;
   visibility: visible;
}
.popup__body {
   min-height: 100%;
   align-items: center;
   justify-content: center;
   padding: 30px 10px;
}

.popup__content {
   background: var(--white);
   max-width: 800px;
   padding: 60px 70px;
   border-radius: 10px;
   position: relative;
}
.popup__title {
   text-align: center;
         font-weight: 900;
      font-size: 36px;
      line-height: 1.2;
      color: #242424;
      text-transform: uppercase;
      margin-bottom: 25px;
  }

  .city__row {
   display: flex;
   margin-bottom: 20px;
  }

  .city__row-title {
   position: relative;
   font-size: 18px;
   color: #242424;
   font-weight: 500;
   flex-grow: 0;
   flex-shrink: 1;
   flex-basis: 120px;
   margin-right: 10px;
  }


  .city__row-name{
   color: #242424;
   font-weight: 500;
   flex: 0 1 auto;
   cursor: pointer;
   transition: color .5s;
  }

  .city__row-name:hover {
   color: var(--main-color);
  }

  .popup__close {
   position: absolute;
   display: block;
   background: url(../images/close-popup.webp);
   background-repeat: no-repeat;
   background-size: cover;
background-position: center center;
    opacity: 0.5;
    width: 40px;
    height: 40px;
    top: 8px;
    right: 8px;
    cursor: pointer;
    transition: opacity 0.3s;
  }

  .popup__close:hover {
   opacity: 1;
  }

  @media (max-width: 767px) {
   .popup__content {
      padding: 60px 30px;
   }
  }

  /* политика конфиденциальности */

  .popup__confidentiality {
   position: fixed;
   overflow: auto;
   width: 100%;
   height: 100%;
   backdrop-filter: blur(5px);
   top: 0;
   left: 0;
   opacity: 0;
   visibility: hidden;
   transition: all .8s;
   z-index: 10;
  }

  .popup__confidentiality.open {
   opacity: 1;
   visibility: visible;
  }

  .popup__confidentiality-content {
     margin: auto;
     padding: 40px 30px;
   margin-top: 30px;
   margin-bottom: 30px;
   position: relative;
   border-radius: 10px;
   min-height: 100%;
   max-width: 978px;
   background-color: var(--white);
   z-index: 100;
  }
  .popup__confidentiality-close {
   position: absolute;
   right: 30px;
   top: 8px;
   font-size: 30px;
   opacity: 0.5;
   cursor: pointer;
   transition: opacity 0.5s;
  }
  .popup__confidentiality-close:hover {
   opacity: 1;
  }

  .popup__confidentiality-content h3 {
   font-size: 25px;
   margin-bottom: 20px;
  }

  .popup__confidentiality-content p {
   margin-bottom: 15px;
  }
  .popup__confidentiality-content h4 {
     font-size: 18px;
     margin-top: 20px;
     margin-bottom: 15px;
  }

  /* сертификат */

  .popup__certificate {
   display: flex;
   align-items: center;
   position: fixed;
   overflow: auto;
   width: 100%;
   height: 100%;
   backdrop-filter: blur(5px);
   top: 0;
   left: 0;
   opacity: 0;
   visibility: hidden;
   transition: all .8s;
   z-index: 10;
   
  }

  .popup__certificate.open {
   opacity: 1;
   visibility: visible;
  }

  .popup__certificate-content {
     margin: auto;
     padding: 60px 30px;
   margin-top: 30px;
   margin-bottom: 30px;
   position: relative;
   border-radius: 10px;
   min-height: 300px;
   background-color: var(--white);
   z-index: 100;
   justify-content: center;
  }
  .popup__certificate-close {
   position: absolute;
   display: block;
   background: url(../images/close-popup.webp);
   background-repeat: no-repeat;
   background-size: cover;
background-position: center center;
    opacity: 0.5;
    width: 40px;
    height: 40px;
    top: 8px;
    right: 8px;
    cursor: pointer;
    transition: opacity 0.3s;

  }
  .popup__certificate-close:hover {
   opacity: 1;
  }

  .popup__certificate-buttons {
   align-items: center;
   width: 700px;
   justify-content: space-around;
   flex-wrap: wrap;
  }

  .popup__certificate-btn {
   padding: 20px 30px;
   background-color: transparent;
   color: var(--black);
   border: 2px solid var(--main-color);
   border-radius: 10px;
   font-size: 25px;
   cursor: pointer;
   transition: background .5s ;
  }

  .popup__certificate-btn:hover {
   background-color: #fdf5e6;
  }

  .popup__certificate-title {
   position: relative;
   color: var(--main-color);
   margin-bottom: 50px;
   text-align: center;
   font-size: 25px;
   text-transform: uppercase;
  }

  /* Окно бронирования */

.popup__reservation{
   position: fixed;
   width: 100%;
   height: 100%;
   backdrop-filter: blur(5px);
   top: 0;
   left: 0;
   opacity: 0;
   visibility: hidden;
   transition: all .8s;
   z-index: 100;
}
.popup__reservation.open {
   opacity: 1;
   visibility: visible;
}

.popup__reservation-body{
   min-height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
}

.popup__reservation-content {
   background: var(--white);
   max-width: 1000px;
   padding: 60px 70px;
   border-radius: 10px;
   position: relative;
}

.popup__reservation-title {
   text-align: center;
   font-weight: 900;
font-size: 36px;
line-height: 1.2;
color: #242424;
text-transform: uppercase;
margin-bottom: 25px;
}

.popup__reservation-subtitle {
   position: relative;
   font-size: 18px;
   color: #242424;
   font-weight: 500;
   flex-grow: 0;
   flex-shrink: 1;
   flex-basis: 120px;
   margin-right: 10px;
}

.popup-reservation__btn {
   position: absolute;
   top: 0;
   right: 0;
   left: auto;
   bottom: auto;
}

.popup-reservation__btn:focus {
   border: 3px solid var(--white);
    background-color:var(--main-color);
    color: var(--white);
 }
 .popup-reservation__btn:hover {
  background-color:var(--white);
  color: var(--main-color);
 }
 
 .popup-reservation__btn:active {
   border: 3px solid var(--white);
    background-color:var(--main-color);
    color: var(--white);
 }

.popup__reservation-subtitle {
   margin-bottom: 30px;
   text-align: center;
  }

  .popup-reservation__form-block {
   width: 100%;
   position: relative;
   max-width: 640px;
   margin-left: auto;
   margin-right: auto;
}

.popup-reservation__inp-phone {
   font-size: 25px;
   border-width: 1px;
   border-color: rgb(235, 235, 235);
   border-style: solid;
   background-color: rgb(255, 255, 255);
   box-shadow: inset 0px 3px 7px 0px rgba(202, 202, 202, 0.5);
   width: 100%;
   height: 70px;
   border-radius: 30px;
   padding-left: 50px;
   outline: none;
}

.popup-reservation__inp-phone::placeholder {
   font-size: 25px;
}

.main2__grafic {
   border-width: 4px;
   border-color: rgb(255, 255, 255);
   border-style: solid;
   border-radius: 35px;
   background-color: rgb(231, 94, 30);
   box-shadow: 0px 5px 20px 0px rgba(231, 94, 30, 0.4), 0px 2px 0px 0px rgba(231, 94, 30, 0.2), inset 0px 4px 7px 0px rgba(161, 51, 0, 0.4);
   position: absolute;
   width: 280px;
   height: 70px;
   bottom: -20px;
   /* left: 0; */
   top: -23px;
   margin: auto;
   z-index: 0;
   justify-content: flex-start;
   -ms-align-items: center;
   align-items: center;
   /* padding-left: 42px; */
   font-size: 20px;
   line-height: 22px;
   color: #fff;
   font-weight: bold;
   -webkit-transition: background 0.3s ease, padding 0.3s ease;
   -moz-transition: background 0.3s ease, padding 0.3s ease;
   -o-transition: background 0.3s ease, padding 0.3s ease;
   -ms-transition: background 0.3s ease, padding 0.3s ease;
   transition: background 0.3s ease, padding 0.3s ease;
}

  @media (max-width: 767px) {
   .popup__certificate-buttons {
      gap: 20px;
      width: 300px;
      margin: auto;
   }
   .popup__certificate-content {
   padding: 40px 15px;
   max-width: 350px;
   }

   .popup__certificate-btn {
      width: 160px;
      font-size: 20px;
   }
   .popup__reservation-content {
      padding: 61px 30px;
  }
   .popup__certificate-title {
      margin-bottom: 30px;
      font-size: 20px;
   }

   .popup__reservation-title {
      font-size: 17px;
   }

   .popup__reservation-subtitle {
font-size: 13px;

   }

   .popup-reservation__btn {
      position: relative;
      width: 100%;
   }

   .popup-reservation__inp-phone {
margin-bottom: 30px;
   }
  }

  /* @media (max-width: 767px) {
   .popup__reservation-title {
      font-size: 17px;
   }

   .popup__reservation-subtitle {
font-size: 13px;
   }

   .popup-reservation__btn {
      position: relative;
      width: 100%;
   }

   .popup-reservation__inp-phone {
margin-bottom: 30px;
   }
  } */