.schedule-day {
   padding-bottom: 100px;
   text-align: center;
}

.schedule-day__descr {
   position: relative;
   max-width: 1000px;
   margin: auto;
   margin-bottom: 100px;
   text-align: center;
   font-size: 25px;
   line-height: 131%;
   letter-spacing: 0.02em;
   color: var(--black);
   font-weight: 400;
}

.schedule-day__descr::after {
   content: url(../images/yellow-triangle.svg);
   position: absolute;
   top: -50px;
   right: 0;
}

.schedule-day__centre-1 {
   position: relative;
}

.schedule-day__centre-1::before {
   content: url(../images/red-yellow.svg);
   position: absolute;
   top: -100px;
   left: -110px;
}

.schedule-day__centre-1::after {
   content: url(../images/red-green-blue.svg);
   position: absolute;
   bottom: -100px;
   left: -50px;
}

.schedule-day__block {
   max-width: 320px;
   text-align: center;
}

.schedule-day__top,
.schedule-day__centre,
.schedule-day__bottom {
   display: flex;
   justify-content: center;
}

.schedule-day__top {
   position: relative;
   margin-bottom: 25px;
   gap: 475px;
}
.schedule-day__top::before {
   content: url(../images/ray-top-red.svg);
   position: absolute;
   top: -75px;
   right: 200px;
}

.schedule-day__top::after {
   content: url(../images/green-triangle.svg);
   position: absolute;
   bottom: -75px;
   right: 200px;
}

.schedule-day__centre {
   margin-bottom: 13px;
   align-items: center;
   gap: 145px;
}

.schedule-day__caption {
   margin-bottom: 13px;
   font-weight: 900;
font-size: 35px;
letter-spacing: 0.02em;
text-transform: uppercase;
color: var(--main-color);
}

.schedule-day__title-descr {
   position: relative;
   margin-top: 7px;
   margin-bottom: 128px;
   font-weight: 500;
font-size: 25px;
letter-spacing: 0.02em;
text-align: center;
color: #000;
}

.schedule-day__title-descr::before {
   content: url(../images/wavy-line-orange.svg);
   position: absolute;
   bottom: -30px;
   right: 38%;
}

.schedule-day__description {
   max-width: 241px;
   font-weight: 300;
font-size: 16px;
line-height: 130%;
letter-spacing: 0.02em;
text-align: center;
color: #000;
}


.schedule-day__subtitle {
   margin-bottom: 13px;
   font-weight: 800;
   font-size: 25px;
   letter-spacing: 0.02em;
   color: var(--black);
}

.schedule-day__bottom {
   margin-bottom: 96px;
   position: relative;
   gap: 119px;
}


.schedule-day__bottom::after {
  content: url(../images/blue-red-yellow.svg);
   position: absolute;
   bottom: 0;
   right: 170px;
}

.schedule-day__bottom::before {
   content: url(../images/ray-bottom-green.svg);
    position: absolute;
    bottom: -30px;
    left: 230px;
 }

.schedule-day__bottom-2 {
   padding-top: 110px;
}

.schedule-day__btn {
margin: auto;
border: 3px solid transparent;
transition: border-color .3s ease, background-color .3s ease, color .3s ease ;
}

.schedule-day__btn:focus {
background-color:var(--main-color);
color: var(--white);
}
.schedule-day__btn:hover {
border: 3px solid var(--main-color);
background-color:var(--white);
color: var(--main-color);
}

.schedule-day__btn:active {
border: 3px solid var(--white);
background-color:var(--main-color);
color: var(--white);
}

@media (max-width: 1024px) {
   .schedule-day {
      padding-bottom: 160px;
   }

   .schedule-day__title-descr {
      margin-bottom: 80px;
   }
   .schedule-day__descr::after {
      transform: scale(0.8);
      right: 25px;
   }
   .schedule-day__title-descr::before {
      transform: scale(0.8);
      bottom: -22px;
      right: 35%;
   }
   .schedule-day__descr {
      font-size: 22px;
      max-width: 600px;
      margin-bottom: 50px;
   }
   .schedule-day__centre-1::before {
      transform: scale(0.8);
      left: 20%;
      top: -315%;
   }

   .schedule-day__caption {
      font-size: 25px;
   }
   .schedule-day__subtitle {
      font-size: 20px;
   }
   .schedule-day__description {
      font-size: 14px;
   }

   .schedule-day__centre-1::after {
      transform: scale(0.8);
      left: 190%;
      bottom: 150%;
   }
   .schedule-day__top::after {
      display: none;
   }
   .schedule-day__top {
      gap: 200px;
   }

   .schedule-day__block {
      max-width: 180px;
   }

   .schedule-day__centre-2 {
      max-width: 214px;
   }
   .schedule-day__centre {
      gap: 60px;
      margin-bottom: 23px;
   }
   .schedule-day__bottom {
      gap: 40px;
      margin-bottom: 55px;
   }

   .schedule-day__top::before {
      transform: scale(0.8);
   }
   .schedule-day__bottom::before {
      transform: scale(0.8);
   }
   .schedule-day__bottom::after {
      transform: scale(0.8);
   }

   .schedule-day__bottom-2 {
      padding-top: 82px;
   }
}

@media (max-width:768px) {

   .schedule-day__top::before {
      top: -32px;
       right: 76px;
   }
   .schedule-day__bottom::before {
      left: 120px;
   }
   .schedule-day__title-descr::before {
      right: 30%;
   }
}

@media (max-width: 767px) {
   .schedule-day {
      padding-bottom: 70px;
   }
   .schedule-day__title-descr::before {
      transform: scale(0.5);
      right: 18%;
      bottom: -15px;
   }
   .schedule-day__descr::after {
      transform: scale(0.5);
      right: -50px;
   }

   .schedule-day__title-descr {
      margin-bottom: 30px;
   }

   .schedule-day__descr {
      font-size: 16px;
      max-width: 226px;
      margin-bottom: 50px;
   }

   .schedule-day__caption,
   .schedule-day__subtitle {
      font-size: 12px;
      margin-bottom: 5px;
   }
   .schedule-day__description {
      font-size: 8px;
   }

   .schedule-day__centre-1::before {
      transform: scale(0.5);
      left: -37%;
   }
   .schedule-day__centre-1::after {
      display: none;
   }
   .schedule-day__top {
      gap: 60px;
   }

   .schedule-day__block {
      max-width: 80px;
   }
   .schedule-day__centre-2 {
      max-width: 102px;
   }

   .schedule-day__centre {
      gap: 15px;
   }
   .schedule-day__bottom {
      margin-bottom: 50px;
      gap: 10px;
   }
   .schedule-day__bottom-2 {
      padding-top: 40px;
   }

   .schedule-day__top::before {
      transform: scale(0.5);
      right: 40px;
      top: -43px;
   }

   .schedule-day__bottom::before {
      left: 40px;
      transform: scale(0.5);

   }
   .schedule-day__bottom::after {
      transform: scale(0.5);
      right: 50px;
      bottom: -30px;
   }
  
   
}