.hero {
   height: 1140px;
   text-align: center;
   background-image: url(../images/hero-bg.png);
   background-repeat: no-repeat;
   background-size: 110%;
   background-position: top  center;

}
.hero__title {
   position: relative;
   margin-bottom: 7px;
   font-weight: 900;
font-size: 65px;
text-transform: uppercase;
color: var(--white);
}
.hero__content {
   position: relative;
}

   .hero__svg-top {
      position: absolute;
      right: 13%;
      top: -64%;
      }

.hero-mobile {
   display: none;
}

.hero__svg-bottom {
   position: absolute;
   left: 16%;
bottom: -111%;
}
.hero__descr {
   position: relative;
   margin-bottom: 6px;
text-align: center;
   font-weight: 600;
   font-size: 35px;
   letter-spacing: 0.02em;
   color: var(--white);
}

.hero__descr::after {
   content: url(../images/wavy-line.svg);
   position: absolute;
   top: 30px;
   left: 38%;
   display: none;
}

.hero__logo {
   max-width: 400px;
   margin: auto;
   margin-bottom: -8px;
}


.hero__btn {
margin: 0 auto;
margin-bottom: 39px;
z-index: 0;
border: 3px solid transparent;
transition: border-color .3s ease , background-color .5s ease, color .3s ease ;
} 

.hero__btn:focus {
   border: 3px solid var(--white);
    background-color:var(--main-color);
    color: var(--white);
 }
 .hero__btn:hover {
  background-color:var(--white);
  color: var(--main-color);
 }
 
 .hero__btn:active {
   border: 3px solid var(--white);
    background-color:var(--main-color);
    color: var(--white);
 }

.hero__show {
   margin-bottom: 15px;
   font-weight: 800;
   color: var(--white);
   font-size: 30px;
}

.hero__date {
   margin-bottom: 5px;
   font-weight: 700;
   font-size: 25px;
   letter-spacing: 0.02em;
   color: var(--white);
}
.hero__place {
   font-weight: 400;
   font-size: 18px;
   letter-spacing: 0.02em;
   color: var(--white);
}

.hero-mobile {
   padding-top: 0;
   padding-bottom: 0;
   text-align: center;
   background-image: url(../images/hero-hare.webp);
   background-repeat: no-repeat;
   background-position: top center;
   height: 538px;
   background-size: 430px;
}

.hero-mobile__title {
   margin-bottom: 5px;
   max-width: 288px;
   margin-left: auto;
   margin-right: auto;
   font-weight: 900;
   font-size: 25px;
   line-height: 132%;
   letter-spacing: 0.02em;
   text-transform: uppercase;
   text-align: center;
   color: var(--white);

}
.hero-mobile__show {
   margin-bottom: 5px;
   font-weight: 800;
   font-size: 12px;
   letter-spacing: 0.02em;
   text-align: center;
   color: var(--white);
}
.hero-mobile__descr {
   margin-bottom: 5px;
   font-weight: 500;
   font-size: 12px;
   line-height: 123%;
   letter-spacing: 0.02em;
   text-align: center;
   color: var(--white);
}
.hero-mobile__date {
   font-weight: 800;
   font-size: 12px;
   letter-spacing: 0.02em;
   text-align: center;
   color: var(--white);
}
.hero-mobile__place {
   font-weight: 400;
   font-size: 10px;
   letter-spacing: 0.02em;
   text-align: center;
   color: var(--white);
}
.hero-mobile__logo {
max-width: 172px;
margin: 0 auto;
margin-top: 134px;
}
.hero-mobile__btn {
margin: 0 auto;
border: none;
}

@media (max-width: 1600px) {
   .hero {
      height: 910px;
   }
}


@media (max-width: 1366px) {
   .hero__btn {
      margin-bottom: 20px;

   }

   .hero__logo {
      max-width: 370px;
   }
}


@media (max-width: 1024px) {

   .hero {
      height: 620px;
   }
   .hero__title {
      font-size: 36px;
   }

   .hero__descr {
      font-size: 20px;
   }

   .hero__logo {
      max-width: 264px;
   }

   .hero__show,
   .hero__date {
      font-size: 20px;
   }

   .hero__place {
      font-size: 16px;
   }
}
@media (max-width: 900px) {
   .hero{
      background-size: 123%;
      height: 600px;
   }
}


@media (max-width: 768px) {
   .hero {
      background-image: url(../images/hero-tablet.png);
      height: 84vw;
      background-size: 100%;
   }
   .hero {
      padding-bottom: 166px;
      padding-top: 35px;
   }



   .hero__svg-bottom {
      left: 50px;
      bottom: -150%;
      width: 22px;
      height: 23px;
   }
   .hero__svg-top {
      right: 6%;
      width: 22px;
      height: 23px;
   }

}

@media (max-width: 767px) {
   .hero-mobile {
      padding-top: 15px;

   }

   .hero-mobile__place {
      position: relative;
   }
   .hero-mobile__place::before {
      content: '';
   position: absolute;
   background-image: url(../images/stars_1.svg);
   width: 46px;
   height: 52px;
   bottom: -50px;

   }
}

@media (max-width: 650px) {

   .hero {
      display: none;
   }
   .hero-mobile {
      display: block;
   }
}


