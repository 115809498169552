.video {
   position: relative;
   margin-top: -92px;
   padding-top: 67px;
   padding-bottom: 100px;
   overflow: hidden;
}

.video::after {
   content: "";
   position: absolute;
   background-image: url(../images/carrot.svg);
   background-repeat: no-repeat;
   background-size: contain;
   width: 135px;
   height: 145px;
   right:9%;
   bottom:60px;
   z-index: 10;
}

.video::before {
   content: url(../images/red-green-blue.svg);
   position: absolute;
   right:10%;
   bottom:60px;
   z-index: 10;
}

.video__title {
   margin-bottom: 7px;
   position: relative;

}

.video__title::after {
   content: url(../images/red-yellow.svg);
   position: absolute;
   left: 100px;
   bottom: -130px;
}

.video__title::before {
   content: url(../images/green-triangle.svg);
   position: absolute;
   right: 23%;
   bottom: -130px;
}


.video__subtitle {
   position: relative;
   margin-bottom: 123px;
}

.video__subtitle::before {
   content: url(../images/wavy-line-orange.svg);
   position: absolute;
   bottom: -22px;
   left: 40%;
}

.video__subtitle::after {
   content: url(../images/ray-top-red.svg);
   position: absolute;
   right:140px;
   bottom: -115px;
}


.video__player {
position: relative;
margin: 0 auto;
max-width: 1106px;
/* padding-top: 56.25%; */
padding-top: 35.25%;
border-radius: 35px;
border: 5px solid var(--main-color);
overflow: hidden;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.video__span {
   position: relative;
}


.video__span::before {
   content: url(../images/ray-bottom-green.svg);
   position: absolute;
   left: 140px;
   bottom: -70px;
}

.video__span::after {
   content: url(../images/blue-red-yellow.svg);
   position: absolute;
   right: 0;
   top: 0;
}

.video__overlay {
   position:absolute;
   left:0;
   top:0;
   right:0;
   bottom:0;
   width: 100%;
   height: 100%;
   /* background-image:url('../images/video-overlay.webp'); */
   /* background-repeat: no-repeat; */
   /* z-index: 10; */
   background-size:cover;
}

.iframe {
   object-fit: cover;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;

   /* display: none; */
}


.video__control {
   position: absolute;
   background-image: url(../images/button-play.svg);
   background-size: cover;
   background-repeat: no-repeat;
   width: 87px;
   height: 87px;
   border-radius: 163px;
   top: 50%;
   left: 50%;
   transform:translate(-50%, -50%);
cursor: pointer;
z-index: 10;
}

@media (max-width: 1024px) {
   .video {
      padding-left: 20px;
      padding-right: 20px;
      margin-top: -70px;
   }

   .video::before {
display: none;
  }

  .video::after {
   transform: scale(0.8);
   bottom: 36px;
   right: -30px;
}

   .video__span::after {
      display: none;
  }

   .video__span::before {
      transform: scale(0.8);
      left: -30px;
      bottom: -50px;
  }

  .video__title::before {
   right: 50%;
   bottom: 200%;
   transform: scale(0.8);
}

   .video__title::after {
      transform: scale(0.8);
      bottom: -80px;
      left: 0;
   }

   .video__subtitle {
      margin-bottom: 70px;
   }

   .video__subtitle::before {
      transform: scale(0.8);
  left: 31%;
  }
   .video__subtitle::after {
      transform: scale(0.8);
      right: -30px;
      bottom: 0;
      top: 40px;
  }

.video__span::after {
   right: -65px;
   top: -220px;
}
}
@media (max-width: 767px) {

   .video {
      padding-bottom: 50px;
      margin-top: -30px;
   }

   .video__player {
      border: 2px solid var(--main-color);
      border-radius: 9px;
      padding-top: 8.25%;
   }
   .video__control {
      width: 44px;
      height: 44px;
   }

   .video__subtitle {
      margin-bottom: 35px;
   }
   .video::after {
      right: -35px;
    bottom: -10px;
      transform: scale(0.5);
   }
   .video__subtitle::before {
      transform: scale(0.5);
      left: 16%;
      bottom: -15px;
   }

   .video__title::after {
      transform: scale(0.5);
   }
   .video__subtitle::after {
      transform: scale(0.5);
   }
   .video__span::before {
      transform: scale(0.5);
      bottom: -40px;
   }
}
