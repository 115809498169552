html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

body {
  font-family: "Raleway", sans-serif;
  margin: 0 auto;
  max-width: 1920px;
}

:root {
  --white: #fff;
  --black: #000;
  --main-color: #e75d1f;
  --green: #8faf2e;
}

.container {
  max-width: 1542px;
  padding: 0 15px;
  margin: 0 auto;
}

.flex {
  display: flex;
}

.btn {
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.title {
  font-weight: 900;
  font-size: 45px;
  letter-spacing: 2%;
  text-align: center;
  text-transform: uppercase;
  color: #000;
}

.title-orange {
  font-weight: 900;
  font-size: 45px;
  letter-spacing: 2%;
  text-transform: uppercase;
  color: var(--main-color);
}

.title-green {
  font-weight: 900;
  font-size: 45px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--green);
}

.title-descr {
  text-align: center;
  font-weight: 500;
  font-size: 25px;
  letter-spacing: 0.02em;
  color: #000;
}

.orange-btn {
  padding: 30px 60px;
  background-color: var(--main-color);
  font-size: 27px;
  font-weight: 900;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  border-radius: 50px;
}

.bold-font {
  font-weight: 700;
}

.green-text {
  color: var(--green);
}

.orange-text {
  color: var(--main-color);
}

@media (max-width: 1024px) {
  .orange-btn {
    padding: 25px 45px;
    font-size: 20px;
  }

  .title,
  .title-green,
  .title-orange {
    font-size: 36px;
  }

  .title-descr {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .title,
  .title-green,
  .title-orange {
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.02em;
  }

  .title-descr {
    font-size: 14px;
  }

  .orange-btn {
    padding: 15px 35px;
    font-size: 16px;
  }
}
