.emotions {
   position: relative;
   padding-top: 110px;
   padding-bottom: 100px;
}

.emotions::before {
   content: url(../images/yellow-red-2.svg);
   position: absolute;
   top: 0;
   right: 120px;
}

.emotions::after {
   content: url(../images/yellow-triangle.svg);
   position: absolute;
   bottom: 0;
   left: 23%;
}

.emotions__title {
   position: relative;
   margin-bottom: 122px;
}

.emotions__title::before{
   content: url(../images/red-green-blue.svg);
   position: absolute;
   top: -40px;
   left: 130px;
}

.emotions__title::after{
   content: url(../images/wavy-line-green.svg);
   position: absolute;
   bottom: -22px;
   right: 25%;
}

.emotions__question {
   margin-bottom: 25px;
   text-align: center;
   font-size: 20px;
   font-weight: 800;
   line-height: 26px;
   letter-spacing: 2%;
   color: #000;
   display: none;
}

.question-orange {
   color: var(--main-color);
}

.emotions__cards {
   position: relative;
   max-width: 296px;
}

.emotions__svg::before {
   content: url(../images/ray-top-red.svg);
   position: absolute;
   top: 231px;
   right: 40px;
}

.emotions__svg::after {
   content: url(../images/ray-bottom-green.svg);
   position: absolute;
   bottom: 55px;
   left: 40px;
   z-index: 100;
}

.emotions__video {
   position: relative;
   margin: 0 auto;
   border: 7px solid var(--main-color);
   border-radius: 18px;
   position: relative;
   overflow: hidden;
   width: 304px;
   height: 540px;
   cursor: pointer;
}

.swiper-button-next, .swiper-button-prev {
   color: var(--main-color);
}

.swiper-button-next:after,
.swiper-button-prev:after {
   font-size: 22px;
}

.emotions__video iframe {
   object-fit: cover;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}

.emotions__video video {
   object-fit: cover;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}

.swiper__emotions {
   max-width: 1400px;
}

@media (max-width: 1024px) {
   .emotions {
      padding-top: 40px;
   }
   .emotions__title::before {
      transform: scale(0.8);
      left: 60px;
      top: -70px;
   }

   .emotions__title::after {
      transform: scale(0.8);
      right: 19%;
   }
   .emotions::before {
      transform: scale(0.8);
      top: -90px;
   }
   .emotions::after {
      transform: scale(0.8);
   }

   .emotions__svg::after {
      transform: scale(0.8);
      left: 98px;
   }

   .emotions__video {
      border: 6px solid var(--main-color);
      width: 250px;
    height: 424px;
   }
   .emotions__svg::before{
      transform: scale(0.8);
      right: 98px;
      top: 165px;
   }
}

@media (max-width: 768px) {
.emotions__svg::before {
   right: 32px;
   top: 164px;
}
.emotions__svg::after {
   left: 40px;
}
.emotions__title::after {
   right: 9%;
}
}

@media (max-width: 767px) {
   .emotions {
      padding-top: 0;
   }
 .emotions__title {
   margin-bottom: 30px;
 }
 .emotions__question {
   display: block;
 }
   .swiper-button-next:after,
   .swiper-button-prev:after {
      font-size: 22px;
   }

   .emotions__title::after  {
      right: -3%;
      bottom: -15px;
   }

   .emotions__title::before,
.emotions__title::after,
.emotions::before,
.emotions::after,
   .emotions__cards::after {
      transform: scale(0.5);
   }

   .emotions__video {
      border: 5px solid var(--main-color);
      width: 192px;
    height: 318px;
   }
   .emotions__svg::before {
      transform: scale(0.5);
      right: 50px;
    top: 20px;
   }
   .emotions__svg::after {
      transform: scale(0.5);
      left: 50px;
   }
}

@media (max-width: 400px)  {
   .emotions__svg::before {
top: 40px;
   }

   .emotions__title::after {
      bottom: -15px;
      right: 30%;
   }
}
