.tooltip-button {
   position: relative;
   display: block;
   width: 59px;
   height: 59px;
   cursor: pointer;
 }

 .popup-text {
   position: absolute;
   text-align: center;
   width: 370px;
   font-weight: 600;
   font-size: 15px;
   line-height: 130%;
   letter-spacing: 0.02em;
   color: #e75d1f;
   background-color: var(--white);
   border-radius: 35px;
   border: 2px solid #e75d1f;
   padding: 15px 15px;
   left: -273%;
   top: -70px;
   opacity: 0;
   transition: opacity 0.3s ease-in-out;
 }
 
 .popup-text::before,
 .popup-text::after {
   content: '';
   border: solid transparent;
   position: absolute;
   left: 50%;
   margin-left: -12px;
   bottom: -32px;
   width: 0;
 }

 .popup-text::after {
    border-top-color:var(--white);
    border-width: 14px;
    margin-left: -9px;
    top: 49px;
 }
.popup-text::before {
   border-top-color: var(--main-color);
   border-width: 16px;
}

 .tooltip-button:hover .popup-text {
   opacity: 1;
 }
 