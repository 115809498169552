.hero__animation-star {
   position: absolute;
   bottom: -350%;
   right: 25%;
}

.star-animate {
   position: relative;
   width: 110px;
   height: 124px;
   display: none;
}

.star-animate__img {
   display: none;
}

.star-animate__img[data-visible] {
   display: block;
 }

.star-animate__1,
.star-animate__2,
.star-animate__3 {
   position: absolute;
   top: 0;
   left: 0;
   opacity: 1;
   width: inherit;
   height: inherit;
}

.hero__animation-flower {
   position: absolute;
   top: 360%;
   left: -3%;
}

.flower-animate {
   position: relative;
   width: 157px;
   height: 219;
   display: none;
}

.flower-animate__img {
   display: none;
}

.flower-animate__img[data-visible] {
   display: block;
 }

.flower-animate__1,
.flower-animate__2,
.flower-animate__3 {
   position: absolute;
   top: 0;
   left: 0;
   width: inherit;
   height: inherit;
}

.hero__animation-wavy {
   position: absolute;
   bottom: -85%;
   left: 38%;
}

.wavy-animate {
   position: relative;
   width: 149px;
   height: 18px;
   display: none;
}
.wavy-animate__img {
   display: none;
}

.wavy-animate__img[data-visible] {
   display: block;
 }


.wavy-animate__1,
.wavy-animate__1,
.wavy-animate__1 {
   position: absolute;
   top: 0;
   left: 0;
   opacity: 1;
   width: inherit;
   height: inherit;
}

.acquaintance__animation-magic {
   position: absolute;
   top: 70%;
   left: 58%;
}
.magic-animate {
   position: relative;
   width: 163px;
   height: 170px;
   display: none;
}

.magic-animate__img {
   display: none;
}

.magic-animate__img[data-visible] {
   display: block;
 }

.magic-animate__1,
.magic-animate__2,
.magic-animate__3 {
   position: absolute;
   top: 0;
   left: 0;
   opacity: 1;
   width: inherit;
   height: inherit;
}

.answers__animation-crown {
   position: absolute;
   top: -5%;
   left: 66%;
}
.crown-animate {
   position: relative;
   width: 91px;
   height: 89px;
   display: none;
}
.crown-animate__img{
   display: none;

}

.crown-animate__img[data-visible] {
   display: block;
 }

.crown-animate__1,
.crown-animate__2,
.crown-animate__3 {
   position: absolute;
   top: 0;
   left: 0;
   opacity: 1;
   width: inherit;
   height: inherit;
}

@media (max-width: 1600px) {
   .hero__animation-flower {
      position: absolute;
      top:250%;
      left: 6%;
   }
}

@media (max-width: 1367px) {
   .hero__animation-flower {
      width: 143px;
      height: 199px;
      top: 150px;
   }
}

@media (max-width: 1025px) {
   .flower-animate {
      width: 80px;
      height: 112px;
   }
}

@media (max-width: 1023px) {
   .flower-animate {
      width:81px;
      height: 113px;
   }
}
@media (max-width: 768px) {
   .hero__animation-flower {
      top: 455%;
      left: -2%;
   }
   .hero__animation-star {
      bottom: -310%;
      right: 8%;
      width: 57px;
      height: 63px;
   }

   .hero__animation-star,
   .hero__animation-flower,
   .hero__animation-wavy,
   .hero__svg-bottom,
   .hero__svg-top,
   .answers__animation-crown,
   .acquaintance__animation-magic {
      display: none;
   }

}


