.fairy-tale {
   padding-top: 100px;
   position: relative;
}
.fairy-tale::before {
   content: url(../images/blue-red-yellow.svg);
   position: absolute;
   top: 40%;
   left: 10%;
}

.fairy-tale::after {
   content: url(../images/green-triangle.svg);
   position: absolute;
   bottom: 0;
   right: 10%;
}


.fairy-tale__title {
   margin-bottom: 6px;
}

.fairy-tale__subtite-descr {
   position: relative;
}

.fairy-tale__subtite-descr::after {
   content: url(../images/wavy-line-orange.svg);
   position: absolute;
   bottom: -22px;
   left: 36%;
}

.fairy-tale__item {
   text-align: center;
height: 241px;
}

.fairy-tale__cards {
   display: grid;
   grid-template-columns: repeat(3, 277px);
   position: relative;
   justify-content: center;
   gap: 55px;
}

.fairy-tale__cards::before {
   content: url(../images/ray-bottom-green.svg);
   position: absolute;
   left: 15%;
   bottom: -20px;
}

.fairy-tale__cards::after {
   content: url(../images/ray-top-red.svg);
   position: absolute;
   right:15%;
  top:-10px;
}


.fairy-tale__number{
   margin-bottom: 10px;
   padding-top: 25px;
   padding-bottom: 25px;
   text-align: center;
   background-image: url(../images/green.svg);
   background-position: center;
   background-repeat: no-repeat;
   background-size: 100px 100px;
   font-size: 45px;
   letter-spacing: 0.02em;
   text-transform: uppercase;
   color: var(--white);
   font-weight: 900;
}

.fairy-tale__text {
   margin-bottom: 100px;
   text-align: center;
   margin-top: 60px;
   font-weight: 300;
   font-size: 20px;
   letter-spacing: 0.02em;
   color: var(--black);
}

.fairy-tale__subtitle {
   margin-bottom: 10px;
   text-align: center;
   font-weight: 800;
   font-size: 25px;
   letter-spacing: 0.02em;
   color: var(--black);
}

.fairy-tale__descr {
   font-size: 16px;
   line-height: 130%;
   letter-spacing: 0.02em;
   color: var(--black);
   font-weight: 300;
}

@media (max-width: 1024px) {
   .fairy-tale {
      padding-top: 50px;
   }
   .fairy-tale::before {
 transform: scale(0.8);
      top: 50%;
      left: 5%;
  }

  .fairy-tale::after {
   display: none;
  }

.fairy-tale__subtite-descr::after {
transform: scale(0.8);
   left:24%;
}

.fairy-tale__text {
   font-size: 16px;
   margin-top: 30px;
   margin-bottom: 60px;
}

.fairy-tale__cards {
   grid-template-columns: repeat(2,277px);
   gap: 30px;
}
.fairy-tale__cards::after {
   right: 5%;
   top: 26px;
   transform: scale(0.8);
}
.fairy-tale__cards::before {
transform: scale(0.8);
   left: 15%;
   bottom: -50px;
}
}

@media (max-width: 767px) {
   .fairy-tale {
      padding-top: 0;
   }
   .fairy-tale__cards {
      display: none;
   }

   .fairy-tale__subtite-descr::after {
      transform: scale(0.4);
      bottom: 6px;
      left: 78px;
   }

   .fairy-tale::before {
      transform: scale(0.5);
      top: 100%;
      left: 75%;
   }
}