.certificate {
   position: relative;
   padding-top: 304px;
   padding-bottom: 35px;
   overflow: hidden;
}
.certificate::before {
   content: url(../images/blue-green-yellow.svg);
position: absolute;
top:200px;
left: 120px;
}

.certificate::after {
   content: url(../images/green-yellow.svg);
   position: absolute;
   bottom:-75px;
   left: 50%;
}


.certificate__gift{
   position: relative;
   margin: auto;
   max-width: 1121px;
   min-height: 600px;
   background: #e3f1b9;
   border: 5px solid var(--green);
   border-radius: 35px;
justify-content: center;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
gap: 40px;
}

.certificate__gift::before {
   content: url(../images/ray-bottom-green.svg);
   position: absolute;
   bottom: -55px;
   left: -55px;
}

.certificate__gift::after {
   content: url(../images/ray-top-green.svg);
   position: absolute;
   top: -55px;
   right: -55px;
}

.certificate__gift-right {
   padding-top: 75px;
   max-width: 600px;
   text-align: start;
}


.certificate__title {
   margin-bottom: 15px;
   font-weight: 900;
font-size: 35px;
letter-spacing: 0.02em;
text-transform: uppercase;
color: var(--black);
}

.certificate__subtitle {
   margin-bottom: 50px;
   font-weight: 400;
font-size: 20px;
line-height: 131%;
letter-spacing: 0.02em;
color: var(--black);
}

.certificate__list {
padding-right: 70px;
}

.certificate__item-1,
.certificate__item-2,
.certificate__item-3 {
   position: relative;

   padding-left:100px;
   margin-bottom: 32px;
   font-weight: 400;
font-size: 16px;
line-height: 131%;
letter-spacing: 0.02em;
color: var(--black);
}


.certificate__item-1::before {
   content:'' ;
   position: absolute;
   background-image: url(../images/crown.svg);
   background-repeat: no-repeat;
   width: 62px;
   height: 61px;
   left: 0;
}
.certificate__item-2::before {
   content:'' ;
   position: absolute;
   background-image: url(../images/balls.svg);
   background-repeat: no-repeat;
   width: 61px;
   height: 61px;
   left: 0;
}
.certificate__item-3::before {
   content:'' ;
   position: absolute;
   background-image: url(../images/present.svg);
   background-repeat: no-repeat;
   width: 61px;
   height: 61px;
   left: 0;
}

.certificate__item-3 {
   margin-bottom: 50px;
}

.certificate__btn {
   position: relative;
   display: block;
   margin: 0 auto;
   border: 3px solid transparent;
   transition: border-color .3s ease, background-color .3s ease, color .3s ease ;
}

.certificate__btn::after {
   content: url(../images/yellow-triangle.svg);
   position: absolute;
   bottom: -150px;
   right: -280px;
}

.certificate__btn:focus {
   background-color:var(--main-color);
   color: var(--white);
}
.certificate__btn:hover {
   border: 3px solid var(--main-color);
 background-color:var(--white);
 color: var(--main-color);
}

.certificate__btn:active {
  border: 3px solid var(--white);
   background-color:var(--main-color);
   color: var(--white);
}

.certificate__btn-mob {
   border: none;
   display:none;
}
@media (max-width: 1366px) {

   .certificate__gift::before {
      left: -10px;
      transform: scale(0.8);
   }

   .certificate__gift::after {
      right: -10px;
      transform: scale(0.8);

   }
   .certificate::before {
      transform: scale(0.8);

   }
   .certificate::after {
      transform: scale(0.8);

   }
   .certificate__btn::after {
      right: 0;
   }
}

@media (max-width: 1023px) {
   .certificate {
      padding-right: 60px;
      padding-left: 60px;
   }
   .certificate__gift {
      flex-direction: column-reverse;
      align-items: center;
   }
   .certificate__title {
      text-align: center;
   }
   .certificate__subtitle {
      text-align: center;
   }

   .certificate__list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
   }

   .certificate__item-1::before,
   .certificate__item-3::before {
      left: 30px;
      top: -18px;
      
   }
   .certificate__item-2::before {
      left: 30px;
      top: -25px;
   }

   .certificate__btn::after {
      display: none;
   }
   .certificate::before,
   .certificate::after,
   .certificate__gift::after,
   .certificate__gift::before {
      transform: scale(0.8);
   }
   .certificate__title {
      font-size: 32px;
   }
}

@media (max-width: 768px) {
   .certificate__gift::before {
      left: -40px;
   }
   .certificate__gift::after {
      right: -40px;
   }
}

@media (max-width: 767px) {
   .certificate {
      padding-right: 15px;
      padding-left: 15px;
      padding-top: 140px;
   }
   .certificate__gift {
      border: 2px solid var(--green);
      border-radius: 15px;
      gap: 0;
   }

   .certificate__title {
      font-size: 20px;
   }

   .certificate__subtitle {
      font-size: 12px;
   }
   .certificate__item-1, .certificate__item-2, .certificate__item-3{
      font-size: 14px;
   }

   .certificate__item-1::before,
     .certificate__item-3::before{
      width: 35px;
      height: 35px;
      background-size: cover;
      top: 14px;
   }

   .certificate__item-2::before {
      width: 25px;
      height: 35px;
      background-size: cover;
      top: 12px;
   }
   .certificate__btn {
      display: none;
   }
   .certificate__btn-mob {
      display: block;
      margin: 0 auto;
   }
   .certificate::before,
   .certificate::after,
   .certificate__gift::after,
   .certificate__gift::before {
      transform: scale(0.5);
   }
   .certificate__gift-right {
      padding-top: 30px;
   }
   .certificate__subtitle {
      margin-bottom: 40px;
   }

   .certificate__gift-left img{
      max-height: 274px;
   }
}
