.schedule {
display: none;
}
.card {
   position: relative;
   display: flex;
   align-items: center;
   border-radius: 47px;
   padding: 10px 70px 35px 83px;
}

.card-close {
   position: absolute;
   top: 0;
   right: 0;
   display: none;
}

.schedule {
   padding-top: 103px;
   padding-bottom: 100px;
}

.schedule__title {
   margin-bottom: 5px;
   position: relative;
}

.schedule__title::before {
   content: url(../images/yellow-red.svg);
position: absolute;
left: 0;
top: 100px;
}


.schedule__title::after {
   content: url(../images/yellow-red.svg);
   position: absolute;
   top: -115px;
   right: 30%;
}

.schedule__subtitle {
   margin-bottom: 137px;
   position: relative;
}

.schedule__subtitle::after {
   content: url(../images/green-triangle.svg);
   position: absolute;
   right: 20%;
   bottom: -60px;
}

.schedule__subtitle::before {
   content: url(../images/wavy-line-green.svg);
   position: absolute;
   left: 50%;
   bottom: 0;
   transform: translate(-120%, 76%);
}

.schedule__main-card {
   flex-direction: column;
   justify-content: space-between;
   padding-left: 56px;
   padding-right: 56px;
   border-radius: 47px;
   border: 3px solid var(--main-color);
background: #fad7c7;
}


.schedule__card-first, 
.schedule__card-third {
   background: #fad7c7;
   cursor: pointer;
   border: 3px solid transparent;
   transition: border 1s ease;
   background-image: url(../images/arrow-orange.svg);
   background-repeat: no-repeat;
   background-position: center 150px;
   bottom: 10px;
   display: none;
}

.schedule__card-second, 
.schedule__card-fourth {
   border: 3px solid transparent;
   background: #e6f0c6;
   cursor: pointer;
   background-image: url(../images/arrow-green.svg);
   background-repeat: no-repeat;
   background-position: center 150px;
   bottom: 10px;
   transition: border 1s ease;
}

.schedule__cards {
   /* display: none; */
   position: relative;
   justify-content: center;
   gap: 20px;
}

.schedule__cards::after {
content: url(../images/blue-red-yellow.svg);
position: absolute;
bottom: 30%;
right: 0%;
}

.schedule__cards::before {
   content: url(../images/yellow-triangle.svg);
   position: absolute;
   bottom: -10%;
   left: 20%;
   }
   

.schedule__number{
   font-size: 75px;
}

.schedule__date{
   flex-direction: column;
   text-align: center;
   margin-right: 20px;
   font-weight: 900;
   font-size: 28px;
   letter-spacing: 0.02em;
}

.schedule__area{
   font-weight: 800;
   font-size: 33px;
   letter-spacing: 0.02em;
}
.schedule__address{
   font-weight: 400;
   font-size: 26px;
   line-height: 130%;
   letter-spacing: 0.02em;
   color: var(--black);
}

.schedule__small-cards {
   flex-direction: column;
   /* justify-content: flex-end; */
   /* justify-content: space-between; */
   justify-content: space-around;
   gap: 20px;
}

.schedule__month {
   font-size: 55px;
}


.schedule__main-date {
   font-size: 65px;
   letter-spacing: 0.02em;
   line-height: 100px;
   text-transform: uppercase;
   color: var(--main-color);
   font-weight: 900;
}

.schedule__list{
   gap: 10px;
}

.schedule__item {
   padding: 8px 19px;
   text-align: center;
   border: 3px solid var(--main-color);
   border-radius: 12px;
   font-weight: 600;
   font-size: 28px;
   letter-spacing: 0.02em;
   text-transform: uppercase;
   color: var(--main-color);
}

.schedule__map {
   margin-bottom: 25px;
   margin-top: 25px;
   border-radius: 36px;
   width: 500px;
   height: 230px;
   border: 3.6125941276550293px solid var(--main-color);
   z-index: 10;
   overflow: hidden;
}


.schedule__location-cinema {
   font-size: 30px;
   letter-spacing: 0.02em;
   color: var(--main-color);
   font-weight: 800;
}

.schedule__location {
   margin-bottom: 25px;
   font-weight: 400;
   font-size: 23px;
   color: var(--black);
}

.schedule__icon  {
   gap: 23px;
}

.schedule__button {
   padding: 25px 60px;
   background-color:var(--main-color);
   font-size: 27px;
   font-weight: 900;
   letter-spacing: 0.03em;
   text-transform: uppercase;
   text-align: center;
   color: #fff;
   border-radius: 50px;
   margin-top: 25px;
    border: 3px solid transparent;
    transition: border-color .3s ease, background-color .3s ease, color .3s ease ;
}

.schedule__button:focus {
   border: 3px solid var(--white);
    background-color:var(--main-color);
    color: var(--white);
 }
 .schedule__button:hover {
  background-color:var(--white);
  color: var(--main-color);
 }
 
 .schedule__button:active {
   border: 3px solid var(--white);
    background-color:var(--main-color);
    color: var(--white);
 }

 .tabs-btn {
   /* flex-grow:1 */
   height: 200px;
 }

 .schedule__button-mob {
   display: none;
   margin: 65px auto;
   border: none;
 }

.schedule__card-first.active,
.schedule__card-third.active {
   border: 3px solid var(--main-color);
}

.schedule__card-second.active,
.schedule__card-fourth.active {
   border: 3px solid var(--green);
}

  .schedule__main-cards .schedule__main-card {
   left: -999px;
   opacity: 0;
   position: absolute;
   transition: all 1s;
 }

 .schedule__main-cards .schedule__main-card.active {
   position: static;
 opacity: 1;
 }

 .modal-open {
   overflow: hidden;
 }
 
 .overlay {
   display: none;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100vh;
   backdrop-filter: blur(5px);
   align-items: center;
   justify-content: center;
   z-index: 100;
   overflow-y: auto;
   overflow-x: hidden;
 }

 .overlay.visible {
   display: flex;
 }

 @media (max-width: 1024px) {
   .schedule__card-first {
      display: flex;
   }
   .card-close {
      display: block;
   }

   .schedule {
      padding-top: 65px;
      padding-bottom: 50px;
   }
   .schedule__card-first.active, .schedule__card-third.active {
      border: none;
   }

   .schedule__subtitle::before {
      transform: scale(0.8);
      bottom: -22px;
      left: 32%;
   }

   .schedule__subtitle::after {
      transform: scale(0.8);
      right: 5%;
   }

   .schedule__title::after {
      content: url(../images/blue-red-yellow-2.svg);
      position: absolute;
      top: -115px;
      right: 30%;
      transform: scale(0.8);
  }


   .schedule__title::before {
      transform: scale(0.8);
      left: 25px;
      top: 50px;
   }

   .schedule__subtitle {
      margin-bottom: 70px;
   }

   .schedule__main-cards{
     display: none;
   }

   .schedule__button-mob {
      display: block;
   }

   .schedule__item {
      padding: 5px 7px;
      font-size: 40px;
      border: 3px solid var(--main-color);
      border-radius: 10px;
   }

   .schedule__cards::before {
      bottom: 16%;
      left: 3%;
  }
  
  .tabs-btn {
   height: 200px;
}
.schedule__card-first.active, .schedule__card-third.active {
   background-position: center 150px;
}

.schedule__card-first, .schedule__card-third {
   background-position: center 150px;
}
 }

 @media (max-width: 767px) {
   .schedule__title::after {
      transform: scale(0.5);
      right: 50%;
      top: -90px;
   }

   .tabs-btn {
      height: 120px;
   }

   .schedule__subtitle::before  {
      transform: scale(0.5);
      left: 25%;
      bottom: -105%;
   }

   .schedule__subtitle::after {
      transform: scale(0.5);
      right: 30%;
      top: -85px;
bottom: 0;
   }

   .schedule__title::before {
      display: none;
   }

   .schedule__subtitle {
      margin-bottom: 25px;
   }

   .schedule__number {
      font-size: 34px;
   }

   .schedule__area {
      font-size: 16px;
   }

   .schedule__address {
      font-size: 14px;
   }

   .schedule__card-first, 
.schedule__card-third {
   background-position: center 75px;
   background-size: 20px 20px;
}

.schedule__card-second, 
.schedule__card-fourth {
   background-image: url(../images/arrow-green.svg);
   background-position: center 75px;
   background-size: 20px 20px;
}

.schedule__card-first, .schedule__card-first.active, .schedule__card-third, .schedule__card-third.active {
   background-position: center 85px;
}

   .card {
      padding: 15px 30px 30px 30px;
      border-radius: 21px;
      background-size: 11px 5px;
   }
   .schedule__main-card {
      border: 2px solid var(--main-color);
   }

   .schedule__cards::after {
      transform: scale(.5);
      left: 82%;
      bottom: -11%;
   }

   .schedule__cards::before {
      display: none;
   }

   .schedule__date {
      font-size: 12px;
   }

   .schedule__main-date {
      font-size: 42px;
      line-height: 50px;
   }
   .schedule__month {
      font-size: 40px;
   }
   .schedule__item {
      padding: 5px 7px;
      font-size: 18px;
      border: 1px solid var(--main-color);
      border-radius: 5px;
   }

   .schedule__location {
      margin-bottom: 10px;
      font-size: 14px;
   }
   .schedule__location-cinema {
      font-size: 16px;
   }

   .schedule__svg {
      transform: scale(0.5);
   }

   .schedule__map {
      margin-bottom: 17px;
      margin-top: 17px;
      width: 221px;
      height: 109px;
      border: 2px solid var(--main-color);
      border-radius: 16px;
   }
   .ymaps-2-1-79-map {
      width: 221px;
      height: 109px;
   }

   .schedule__icon  {
      gap: 0px;
      /* align-self: flex-start; */
   }
   .schedule__button {
      padding: 15px 20px;
      margin-top: 17px;
      font-size: 25px;
   }

   .schedule__button-mob {
      position: relative;
      margin-top: 30px;
      margin-bottom: 30px;
   }
   .schedule__button-mob::after {
      content: url(../images/yellow-red.svg);
      position: absolute;
      bottom: -200%;
      left: 10%;
      transform: scale(0.5);
   }

 }