.education {
   padding-top: 12px;
}
.education__caption {
   text-align: center;

}

.education__title {
   position: relative;
}

.education__title::before {
   content: url(../images/red-white.svg);
   position: absolute;
   top: -230px;
   right: 130px;
}

.education__subtitle {
   margin-bottom: 130px;
   position: relative;
   display: inline-block;
}

.education__subtitle::after {
   content: url(../images/wavy-line-green.svg);
   position: absolute;
   bottom: -22px;
   right: 0;
}

.education__left-caption, .education__right-caption {
   margin-bottom: 50px;
   background-image: url(../images/education-oval.svg);
   background-repeat: no-repeat;
background-position: left;
width: 281px;
height: 146px;
padding-top: 52px;
font-family: var(--font-family);
font-weight: 900;
font-size: 35px;
letter-spacing: 0.02em;
text-align: center;
color: var(--white);
}

.education__content {
   margin-bottom: 183px;
   justify-content: center;
   gap: 100px;
}

.education__left, .education__right {
   max-width: 475px;
   position: relative;
}

.education__left::before {
content: url(../images/red-yellow-2.svg);
position: absolute;
top: -65px;
left: -100px;
}

.education__left::after {
   content: url(../images/ray-bottom-green.svg);
   position: absolute;
   bottom: -65px;
   left: -100px;
   }

.education__right::before {
   content: url(../images/ray-top-red.svg);
   position: absolute;
   top: 30%;
   right: 0;
}   

.education__right::after {
   content: url(../images/blue-red-yellow.svg);
   position: absolute;
   bottom: -10%;
   right: -20%;
}   


.education__item {
   position: relative;
   padding-left: 35px;
   margin-bottom: 15px;
   font-weight: 400;
   font-size: 16px;
   line-height: 131%;
   letter-spacing: 0.02em;
   color: var(--black);
}

.education__item::before {
   content:'' ;
   position: absolute;
   background-image: url(../images/education-vector.svg);
   width: 24px;
   height: 25px;
   left: 0;
}

@media (max-width: 1366px) {
   .education__right::after {
      transform: scale(0.8);
      bottom: -35%;
    right: -5%;
   }
   .education__left::before{
      left: 0;
   }
   .education__left::after{
      left: 0;
   }
   .education__right::after{
      right: 0;
   }
}

@media (max-width: 1023px) {
   .education__content {
      flex-direction: column;
   }

   .education__left, .education__right {
      margin: auto;
   }

   .education__title::before {
      display: none;
   }

   .education__left::before{
      transform: scale(0.8);
   }

   .education__subtitle::after {
      transform: scale(0.8);
   }
   .education__left::after {
      transform: scale(0.8);
      bottom: -150%;
      left: -30px;
   }

   .education__right::before {
      transform: scale(0.8);
      top: -80%;
   }

}
@media (max-width: 768px) {
.education__subtitle {
   margin-bottom: 75px;
}
.education__subtitle::after {
   right: -20px;
}
.education__content {
   gap: 65px;
   margin-bottom: 132px;
}
.education__right::after {
   bottom: -25px;
}
}

@media (max-width: 767px) {
   .education__subtitle::after {
      transform: scale(0.5);
      bottom: -15px;
      right: -36px;
   }

   .education__content {
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 80px;
   }
   .education__item {
      font-size: 14px;
   }

   .education__right::before {
      transform: scale(0.5);
right: 0;
   }

   .education__right::after {
      transform: scale(0.5);
      right: 0;

   }

   .education__left::after {
      transform: scale(0.5);
left: 0;
bottom: -145%;
   }

   .education__subtitle {
      margin-bottom: 60px;
   }

   .education__left-caption, .education__right-caption {
      margin-bottom: 20px;
      background-image: url(../images/education-oval-mob.svg);
      padding-top: 28px;
      width: 155px;
    height: 80px;
    font-size: 20px;
   }
   .education__left::before {
      transform: scale(0.5);
      top: -5px;
      left: 60%;
   }

}
@media (max-width: 430px){
   .education__right::before {
      top: -100%;
   }
   .education__right::after {
      bottom: -20%;
   }
   .education__left::after {
      left: -17px;
    bottom: -150%
   }
}