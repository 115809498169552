.show {
   overflow: hidden;
   padding-top: 103px;
   padding-bottom: 100px;
   text-align: center;
   position: relative;
}

.show::before {
   content: url(../images/green-triangle.svg);
   position: absolute;
   left: 100px;
   top: 42%;
}

.show::after {
   content: url(../images/blue-red-yellow.svg);
   position: absolute;
   right: 55px;
   top: 55%;
}

.show__title {
   position: relative;
}

.show__title::after {
   content: url(../images/wavy-line-orange.svg);
   position: absolute;
   bottom: -30px;
    left: 17%;
}

.show__title::before {
   content: url(../images/red-yellow.svg);
   position: absolute;
   bottom: -190px;
   left: 27%;
   z-index: 10;
}

.show__images {
   margin-bottom: 110px;
   position: relative;
   flex-wrap: wrap;
   margin-top: 120px;
   justify-content: center;
   gap: 20px;
}

.show__images::before {
   content: url(../images/red-green-blue.svg);
   position: absolute;
   top: -50px;
   left: 100px;
}

.show__images::after {
   content: url(../images/ray-top-red.svg);
   position: absolute;
   top: -50px;
   right: 100px;
}

.show__item {
   position: relative;
   overflow: hidden;
   border: 5px solid var(--main-color);
   border-radius: 35px;
   box-shadow: 0px 0px 25px 0px rgba(92, 38, 13, 0.25);
}

.show__name {
   text-align: start;
   padding-left: 10px;
   position: absolute;
   bottom: 50px;
   left: 50px;
   font-weight: 900;
font-size: 39px;
letter-spacing: 0.02em;
color: var(--white);
}

.show__item-descr {
   margin-top: 15px;
   font-weight: 700;
font-size: 24px;
letter-spacing: 0.02em;
color: var(--white);
}

.show__btn {
   position: relative;
   margin: 0 auto;
   border: 3px solid transparent;
   transition: border-color .3s ease, background-color .3s ease, color .3s ease ;
}

.show__btn::before {
   content: url(../images/ray-bottom-green.svg);
   position: absolute;
   top: -115px;
   left: -140%;
}

.show__btn::after {
   content: url(../images/yellow-triangle.svg);
   position: absolute;
   bottom: 20px;
   right: -80%;
}

.show__btn:focus {
   background-color:var(--main-color);
   color: var(--white);
}
.show__btn:hover {
   border: 3px solid var(--main-color);
 background-color:var(--white);
 color: var(--main-color);
}

.show__btn:active {
  border: 3px solid var(--white);
   background-color:var(--main-color);
   color: var(--white);
}

@media (max-width: 1024px) {
   .show {
      padding-top: 0;
   }

   .show::after {
      top: 80%;
      transform: scale(0.8);
   }
   .show__title {
      max-width: 450px;
      margin: auto;
   }
   .show__item {
     max-width: 297px;
 }

 .show__images {
margin-top: 60px;
margin-bottom: 35px;
 }

 .show__title::after {
   transform: scale(0.8);
   bottom: 26px;
   left: 6%;
 }

 .show__images::before {
   top: -230px;
   transform: scale(0.8);
 }

 .show__images::after {
   transform: scale(0.8);
   top: -40px;
    right: 10px;
 }
 .show__title::before {
   display: none;
 }
 .show__btn::before {
   left: 0;
   transform: scale(0.8);
 }
 .show__name {
   text-align: left;
   font-size: 25px;
   bottom: 20px;
   left: 15px;
 }

 .show__item-descr {
   margin-top: 0;
 }

 .show__item-descr {
   font-size: 16px;
 }

 .show__btn::after {
   display: none;
 }
}
@media (max-width: 768px) {

   .show__btn::before{
      top: -80%;
      left: -80%;
   }
}

 @media (max-width: 767px) {
   .show {
      padding-bottom: 80px;
   }
   .show::after {
      transform: scale(0.5);
      right: 26px;
   }
   .show__item {
     max-width: 248px;
     margin-bottom: 25px; 
     border-radius: 15px;
     border: 2px solid var(--main-color);    
   } 

   .show__images {
      margin-top: 25px;
      margin-bottom: 5px;
   }

   .show__title::after{
      transform: scale(0.5);
      bottom: 12px;
      left: 11%;
   }
   .first,
   .second,
   .third,
   .fourth,
   .fifth,
   .sixth,
   .seventh,
   .eight {
   transform: none;      
}
.nineth {
   position: relative;
   top: 0;
   left: 0;
}
.show__btn::before {
   transform: scale(0.5);
   top: -43px;
   left: -56px
}

.show__images::after {
   transform: scale(.5);
}

.show__name {
   padding-left: 0;
}
.show__images::before {
   transform: scale(0.5);
   top: 140px;
   left: -5px;
}
 }
