.plot {
   padding-top: 74px;
   text-align: center;
   position: relative;
}
.plot::after {
   content: url(../images/blue-green-red.svg);
   position: absolute;
   bottom: -100px;
   right: 43%;
}

.plot__title {
   position: relative;
}

.plot__title::before {
   content: url(../images/blue-red-yellow-2.svg) ;
   position: absolute;
   left: 40px;
   bottom: -100px;
}

.plot__title::after {
   content: url(../images/red-green-blue.svg) ;
   position: absolute;
   right: 55px;
   bottom: 55px;
}


.plot__descr {
   margin-top: 5px;
   margin-bottom: 128px;
   position: relative;
}

.plot__descr::after {
   content: url(../images/wavy-line-green.svg);
   position: absolute;
   bottom: -22px;
   left: 40%;
}


.plot__subtitle {
   margin-bottom: -4px;
   font-weight: 800;
font-size: 25px;
letter-spacing: 0.02em;
color: var(--black);
}

.plot__cards {
   position: relative;
}

.plot__text {
   margin: auto;
   margin-bottom: 205px;
   max-width: 1000px;
   font-size: 25px;
   line-height: 130%;
   letter-spacing: 0.02em;
   text-align: center;
   color: var(--black);
   font-weight: 300;
}

.plot__images {
   position: relative;
}

.plot__images:hover .plot__img-hare {
   transform: scale(1.2);
}

.plot__images:hover .plot__img-circle {
   transform: scale(0.8);
}

.plot__img-hare {
   position: absolute;
   bottom:0;
   left: 3px;
   transition: 2s
}

.plot__img-circle{
   margin: auto;
   margin-bottom: 27px;
   transition: 2s;
}

.plot__kroks, .plot__krokky, .plot__kroha, .plot__grandpa {
   max-width: 318px;
   margin: 0 auto;
}

.plot__description {
   padding: 15px;
   font-weight: 300;
font-size: 16px;
line-height: 130%;
letter-spacing: 0.02em;
text-align: center;
color: #000;
}

.plot__content-slider {
   position: relative;
}


.plot-button-prev {
   position: absolute;
   top: 50%;
   left: 10%;
   z-index: 10;
}


.plot-button-next {
   position: absolute;
   top: 50%;
   right: 10%;
   z-index: 10;
}

.plot-button-next.swiper-button-disabled,
.plot-button-prev.swiper-button-disabled {
   opacity: 0.5;
}

.swiper__plot {
   padding-top: 100px;
}

@media (max-width: 1024px) {
   .plot__text {
      margin-bottom: 35px;
   }
   .plot__descr {
      margin-bottom: 80px;
   }
   .plot::after ,
   .plot__title::after,
   .plot__title::before {
      transform: scale(0.8);
   }
   .plot__title::after{
      right: 4px;
       bottom: -311px;
   }

   .plot__descr::after {
      transform: scale(0.8);
      left: 37%;
   }
   .plot__text{
      font-size: 22px;
      max-width: 600px;
   }
   .plot-button-next {
      right: 3%;
   }

   .plot-button-prev {
      left: 3%;
   }
}
@media (max-width: 767px) {
   .plot__images:hover .plot__img-hare {
      transform: none;
   }
   
   .plot__images:hover .plot__img-circle {
      transform: none;
   }

   .plot__title::after,
   .plot__title::before {
      transform: scale(0.5);
   }

   .plot__title::before {
      bottom: -70px;
   }
   
   .plot__descr::after {
      transform: scale(0.5);
left: 20%;
bottom: -15px;
   }
   .plot {
      padding-top: 0;
   }
   .plot::after {
      transform: scale(0.5);
   }
   .plot__descr {
      margin-bottom: 30px;
   }

   .plot__text {
      font-size: 16px;
   }
   .plot__img-hare {
      max-height: 275px;
      bottom: -1px;
      left: 28px;
   }

   .plot__img-circle {
      max-height: 188px;
   }

   .plot-button-prev,
   .plot-button-next {
      top: 40%;
   }

   .plot__subtitle{
      font-size: 20px;
   }
   .plot__description {
      font-size: 14px;
   }
}
