.answers {
   padding-top: 93px;
   text-align: center;
   overflow: hidden;
}

.answers__title {
   margin-bottom: 7px;
}

.answers__title-descr {
   display: inline-block;
   position: relative;
}

.answers__title-descr::after {
   content: url(../images/wavy-line-orange.svg);
   position: absolute;
   bottom: -22px;
   left: 16%;
}

.answers__title-descr::before {
   content: url(../images/green-triangle.svg);
   position: absolute;
   bottom: 25px;
   right: -10%;
}

.answers__caption {
   margin-bottom: 45px;
   font-weight: 800;
font-size: 25px;
line-height: 131%;
letter-spacing: 0.02em;
color: var(--green);
}

.answers__subtitle {
   position: relative;
   font-weight: 800;
font-size: 35px;
line-height: 131%;
letter-spacing: 0.02em;
color: var(--black);
margin: 127px auto 31px;
}

.answers__subtitle::before {
   content: url(../images/red-yellow.svg);
   position: absolute;
   left: 50px;
   top: -100px;
}

.answers__desr {
   max-width: 1125px;
   margin: 0 auto;
   font-weight: 400;
font-size: 25px;
line-height: 131%;
letter-spacing: 0.02em;
color: #000;
}

.answers__text {
   margin-bottom: 45px;
   text-align: start;
   font-weight: 800;
font-size: 16px;
line-height: 131%;
letter-spacing: 0.02em;
color: var(--black);
}

.answers__content {
   margin-top: 100px;
   justify-content: center;
   gap: 20px;
}

   .answers__photo {
      max-width: 459px;
   }


.answers__list {
   margin-bottom: 49px;
   text-align: start;
   max-width: 425px;
}

.answers__item {
   position: relative;
   padding-left: 30px;
   margin-bottom: 16px;
   font-weight: 400;
   font-size: 16px;
   line-height: 131%;
   letter-spacing: 0.02em;
   color: var(--black);
}

.answers__item::before {
   content:'' ;
   position: absolute;
   background-image: url(../images/green-vector.svg);
   width: 24px;
   height: 25px;
   left: 0;
}

.answers__left-content {
   position: relative;
}

.answers__left-content::before {
   content: url(../images/red-green-blue.svg);
   position: absolute;
   top: 25%;
   left: -145px;
}

.answers__left-content::after {
   content: url(../images/ray-bottom-green.svg);
   position: absolute;
   bottom:0;
   left: 0;
}

.answers__right-content {
   position: relative;
   padding-top: 60px;
   text-align: start;
   max-width: 500px;
   align-items: flex-start;
   flex-direction: column;
   justify-content: center;
}

.answers__right-content::before {
content: url(../images/ray-top-red.svg);
position: absolute;
top: 0;
right: -24px;
}

.answers__right-content::after {
   content: url(../images/blue-red-yellow.svg);
   position: absolute;
   top: 50%;
   right:-120px;
   }

.answers__name {
   font-weight: 800;
font-size: 20px;
letter-spacing: 0.02em;
}

.answers__director {
   position: relative;
   font-weight: 400;
font-size: 16px;
color: var(--black);
}

.answers__director::after {
   content: url(../images/yellow-red.svg);
   position: absolute;
   left: 15%;
   bottom: -150px;
}


.answers__director::before {
   content: url(../images/yellow-triangle.svg);
   position: absolute;
   right: 0;
   bottom: -75px;
}

.answers__descr-container {
position: relative;
   background-image: url(../images/answers-bg.svg);
   background-repeat: no-repeat;
   background-size: cover;
   padding-top: 300px;
   padding-bottom: 345px;
}

.answers__descr-container::before {
   content: url(../images/small-carrot.svg);
   position: absolute;
   left: 100px;
   top: 37%;
}

.answers__descr-container::after {
   content: url(../images/red-yellow-white.svg);
   position: absolute;
   left: 90px;
   top: 90px;
}

.answers__descr {
   position: relative;
   margin: 0 auto;
   max-width: 1000px;
   font-weight: 400;
font-size: 35px;
line-height: 131%;
letter-spacing: 0.02em;
color: var(--black);
}

.answers__descr::before {
   content: url(../images/ray-bottom.svg);
   position: absolute;
   left: 0;
   bottom: -111px;
}

.answers__descr::after {
   content: url(../images/ray-top.svg);
   position: absolute;
   right: -20px;
   top: -50px;
}
@media (max-width: 1366px) {

   .answers__left-content::before {
      left: -13px;

   }
   .answers__right-content::after {
      right: -13px;
   }

   .answers__descr::after {
      right: -4px;
   }
   .answers__descr {
      font-size: 25px;
      max-width: 570px;
   }
}
@media (max-width: 1024px){

   .answers__span-img {
      position: absolute;
      background-image: url(../images/crown_1.svg);
      background-repeat: no-repeat;
      background-size: cover;
      width: 87px;
      height: 90px;
      left: 67%;
      top: -7%;
   } 
}

@media (max-width: 1023px){

   .answers__content {
      margin-top: 60px;
   flex-direction: column;
   align-items: center;
   }

   .answers__subtitle {
      margin-top: 75px;
      font-size: 30px;
      max-width: 575px

   }
   .answers__desr {
      font-size: 22px;
      max-width: 600px;
   }

   .answers__subtitle::before {
      transform: scale(0.8);
      left: -60px;
   }
   .answers__title-descr::after {
      transform: scale(0.8);
      left: 26%;
   }

   .answers__left-content::before,
   .answers__right-content::before {
      transform: scale(0.8);
   }
   .answers__right-content::after {
      transform: scale(0.8);
      top: 90%;
      right: 10px
   }

   .answers__director::before {
      display: none;
   }

   .answers__left-content::after {
      transform: scale(0.8);
      bottom: -95%;
      left: -15%;
      z-index: 100;
   }

   .answers__descr-container {
      padding-top: 120px;
      padding-bottom: 120px;
   }

   .answers__descr {
      font-size: 25px;
      max-width: 570px;
   }
   .answers__descr-container::after {
      display: none;
   }
   .answers__descr::before,
   .answers__descr::after {
      transform: scale(0.8);
   }

   .answers__descr::before {
      bottom: -60px;
   }
   .answers__descr-container::before {
      transform: scale(0.5);
      left: 10px;
    top: 23%;
   }
   .answers__director {
      margin-bottom: 30px;

   }

   .answers__director::after {
      transform: scale(0.8);
      bottom: -100px;
   }
}

@media (max-width: 768px) {
   .answers__right-content {
      padding-top: 0;
   }
   .answers__name {
      margin-top: 50px;
   }
   .answers__left-content::after {
      bottom: -93%;
      left: -15%;
  }
}

@media (max-width: 767px) {
.answers {
   padding-top: 0;

}
.answers__span-img {
   width: 48px;
   height: 49px;
   left: 70%;
    top: -3%;
}

.answers__title-descr::before {
   display: none;
}

   .answers__title-descr::after {
      transform: scale(0.5);
      left: 6%;
      bottom: -15px;
   }

   .answers__subtitle::before {
      left: 5px;
      top: 35px;
      transform: scale(0.5);
   }

   .answers__subtitle {
      font-size: 20px;
   }

   .answers__desr {
      font-size: 16px;
      max-width: 250px;
   }

   .answers__photo {
      max-width: 323px;
    background-size: 229px 226px;
    background-position: bottom 7px left 3px;
   }

   .answers__caption{
      font-size: 20px;
      margin-bottom: 20px;
   }
   .answers__item {
      font-size: 14px;
   }
   .answers__right-content {
      padding-top: 20px;
      padding-right: 25px;
      padding-left: 25px;
   }

   .answers__left-content {
      padding-left: 25px;

   }

   .answers__right-content::before {
      transform: scale(0.5);
      right: 10px;
      top: 20px;
   }
   .answers__left-content::after {
      transform: scale(0.5);
      bottom: -136%;
      left: -6%;
   }
   .answers__right-content::after {
      transform: scale(0.5);

   }

   .answers__descr {
      font-size: 16px;
      max-width: 226px;
   }
   .answers__descr::after, 
   .answers__descr::before {
      transform: scale(0.5);
   }

   .answers__descr::after  {
      top: -30px;
   }
   .answers__descr-container {
      padding-top: 100px;
      padding-bottom: 100px;
   }
   .answers__descr-container::before {
      left: -10px;
      top: 10%;
   }

   .answers__left-content::before, .answers__right-content::before {
      transform: scale(0.5);
   }
   .answers__director::after {
      transform: scale(0.5);
      bottom: -85px;
   }
}

