.header {
   position: fixed;
   left: 0;
   top: 0;
   right: 0;
   z-index: 100;
   align-items: center;
   display: none;
   height: 100px;
   background-color: var(--green);
   background-image: url(../images/header-tree.webp);
   background-repeat: no-repeat;
   background-size: cover;
}

.modal-open {
   overflow: hidden;
 }

.header-block {
   display: flex;
}

.header-2 {
   padding-top: 20px;
   left: 0;
   top: 0;
   right: 0;
   z-index: 100; 
   align-items: center;
      height: 125px;
      background-color: var(--green);
      background-image: url(../images/header-tree.webp);
      background-repeat: no-repeat;
      background-size: cover;
}

.header__container {
   max-width: 1400px;
   flex-grow: 1;
   align-items: center;
   justify-content: space-between;
   margin: 0 auto;
   padding: 0 15px;
}

.menu__icon {
   display: none;
}

.nav__list {
   align-items: center;
   gap: 48px;
}

.nav__link {
   font-size: 25px;
   letter-spacing: 0.02em;
   color: var(--white);
   text-transform: uppercase;
   font-weight: 800;
}

.header__btn {
   padding: 12px 30px;
   background-color: var(--white);
   font-weight: 900;
   letter-spacing: 0.03em;
   text-transform: uppercase;
   text-align: center;
   color: var(--main-color);
   cursor: pointer;
   border-radius: 50px;
   border: none;
   font-size: 21px;
    border: 3px solid transparent;
    transition: border-color .3s ease, background-color .3s ease, color .3s ease ;
}

.header__btn-red {
   position: relative;
   border-width: 4px;
   border-color: rgb(255, 255, 255);
   border-style: solid;
   background-color: rgb(232, 37, 40);
   box-shadow: 0px 5px 20px 0px rgba(232, 37, 40, 0.4), 0px 2px 0px 0px rgba(232, 37, 40, 0.2), inset 0px 4px 7px 0px rgba(161, 51, 0, 0.4);
   height: 60px;
   /* display: flex;
   align-items: center; */
   padding-left: 42px;
   font-size: 16px;
   color: #fff;
}


.header__btn-red::after {
   content: '';
   position: absolute;
   width: 92px;
   height: 80px;
   top: -17px;
   left: -27px;
   /* z-index: 1000; */
   background: url(../images/head-ny.png) no-repeat bottom center;
   background-size: contain;
   /* background-clip: padding-box; */
   padding-bottom: 27px;
}

.header__btn-red-mob {
   position: relative;
   border-width: 4px;
   border-color: rgb(255, 255, 255);
   border-style: solid;
   background-color: rgb(232, 37, 40);
   box-shadow: 0px 5px 20px 0px rgba(232, 37, 40, 0.4), 0px 2px 0px 0px rgba(232, 37, 40, 0.2), inset 0px 4px 7px 0px rgba(161, 51, 0, 0.4);
   height: 60px;
   display: flex;
   align-items: center;
   padding-left: 42px;
   font-size: 21px;
   color: #fff;
   display: none;
}

.header__btn-red-mob::after {
   content: '';
   position: absolute;
   width: 92px;
   height: 80px;
   top: -17px;
   left: -27px;
   /* z-index: 1000; */
   background: url(../images/head-ny.png) no-repeat bottom center;
   background-size: contain;
   padding-bottom: 27px;
}


.header__btn:focus {
   border: 3px solid var(--main-color);
    background-color:var(--white);
    color: var(--main-color);
}
.header__btn:hover {
  background-color:var(--main-color);
  color: var(--white);
}

.header__btn:active {
   border: 3px solid var(--main-color);
    background-color:var(--white);
    color: var(--main-color);
}

.header__item {
   position: relative;
   font-weight: 800;
font-size: 25px;
letter-spacing: 0.02em;
text-align: center;
color: #fff;

}

.header__hover {
position: absolute;
top:25px;
left: 0;
width: 100%;
opacity: 0;
transition: all .5s ease;
}

.header__item:hover .header__hover {
   opacity: 1;
}

.header__phone-block {
   position: relative;
   font-weight: 800;
   letter-spacing: 0.02em;
   font-size: 25px;
   color: var(--white);
}

.header__phone-block:hover .header__hover {
   opacity: 1;
}
.burger {
   display: none;
}

.header--hidden {
   display: none;
}


@media (max-width: 1024px) {
   .header,
   .header-2 {
      height: 100px;
   }
   .header__btn {
      padding: 16px 33px 16px 33px;
      font-size: 16px;
   }

   .header__logo {
      max-width: 148px;
   }
   .nav {
      display: none;
   }

   .header__phone-block {
      font-size: 20px;
   }

   .menu__icon {
      z-index: 110;
      display: block;
      position: relative;
      width: 24px;
      height: 18px;
      cursor: pointer;
      overflow: auto;
   }

   .menu__icon span,
   .menu__icon::before,
   .menu__icon::after {
      left: 0;
      position: absolute;
      height: 10%;
      width: 100%;
      transition: all .3s ease 0s;
      background-color: var(--white);
   }

   .menu__icon::before,
   .menu__icon::after {
      content: '';
   }

   .menu__icon::before {
      top: 0;
   }

   .menu__icon::after {
      bottom: 0;
   }

   .menu__icon span {
      top: 50%;
      transform: scale(1) translate(0px, -50%);
   }

   .menu__icon._active  span{
      transform: scale(0) translate(0px, -50%);
   }

   .menu__icon._active::before {
      top: 50%;
      transform: rotate(-45deg) translate(0px, -50%);
   }

   .menu__icon._active::after {
      bottom: 50%;
      transform: rotate(45deg) translate(0px, 50%);
   }

   .burger {
      display: block;
      position: fixed;
      right: 0;
      top: -100%;
      width: 100%;
      height: 100%;
      background: #e6f0c6;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.9) 50%, rgba(0, 0, 0, 0) 100%);
      padding: 50px 30px 30px 30px;
      transition: top .3s ease 0s;
      z-index: 100;
   }

   .burger._active {
      top: 0;
   }

   .burger__item {
      font-size: 16px;
      line-height: 1.2;
      color: #fff;
      text-align: left;
      margin-bottom: 10px;
   }
   .burger__phone-block {
      font-weight: 900;
      font-size: 16px;
      line-height: 1.2;
      color: #fff;
      margin-bottom: 10px;
   }

   .burger__link {
      display: block;
      font-size: 16px;
      line-height: 1.2;
      color: #fff;
      text-align: left;
      margin-bottom: 10px;
  }

  .burger__select-sity {
   align-items: center;
  }

  .burger__select-sity-icon {
   background-image: url(../images/select-city-icon.svg);
   background-repeat: no-repeat;
   width: 19px;
   height: 24px ;
   background-position: center;
  }

  .burger__select-sity-text {
   margin-left: 20px;
   font-weight: 500;
   color: var(--white);
  }
   }

   @media (max-width: 1023px) {
      .header__select-sity-none {
         display: none;
      }
   }

   @media (max-width: 768px) {
      .header__btn-red {
         display: none;
      }
      .header__btn-red-mob {
         display: block;
      }
   }

   @media (max-width: 767px) {

      .header,
      .header-2 {
         height: 64px;
      }
.header__logo {
   max-width: 95px;
}

      .phone__block {
         display: none;
      } 

   }


   @media (max-width: 600px) {
.header__btn-red-mob {
   display: flex;
   margin-right: 20px;
   align-items: center;
   width: 132px;
        height: 40px;
        border-width: 2px;
        padding-left: 13px;
        padding-right: 8px;
        font-size: 10px;
        margin-left: 10px;
        font-weight: 800;
}

.header__btn-red-mob::after {
  
   width: 42px;
   height: 80px;
   top: -43px;
   left: -14px;
}
   }
