.acquaintance {
   padding-top: 295px;
   padding-bottom: 300px;
   background-image: url(../images/bg-acquantance.webp);
   background-repeat: no-repeat;
   background-size: cover;
}

.acquaintance__title {
   position: relative;
}

.acquaintance__title::before {
   content: url(../images/white-yellow.svg);
   position: absolute;
   bottom: -72px;
   left: 7%;
}

.acquaintance__title::after {
   content: url(../images/green-triangle.svg);
   position: absolute;
   top: -50px;
   right: 5%;
}

.acquaintance__left-content {
   position: relative;
}

.acquaintance__left-content::before {
   content: url(../images/white-green-green.svg);
   position: absolute;
   top: 40%;
   left: -20%;
   display: none;
}

.acquaintance__left-content::after {
   content: url(../images/ray-bottom-green-green.svg);
   position: absolute;
   bottom: -40px;
   left: 8%;
}

.acquaintance__content {
   justify-content: center;
   align-items: center;
   gap: 15px;
}

.acquaintance__title-right {
   margin-bottom: 44px;
   font-weight: 800;
font-size: 25px;
line-height: 131%;
letter-spacing: 0.02em;
color: var(--black);
}

.acquaintance__subtitle {
   margin-top: 7px;
   margin-bottom: 129px;
}

.acquaintance__right-content {
   max-width: 500px;
   position: relative;
}

.acquaintance__right-content::before {
   content: url(../images/ray-top.svg);
   position: absolute;
   top: -65px;
   right: -40px;
}

.acquaintance__right-content::after {
   content: url(../images/green-white-yellow.svg);
   position: absolute;
   top: 40%;
   right: -120px;
}


.acquaintance__name {
   font-weight: 800;
font-size: 20px;
letter-spacing: 0.02em;
color: var(--green);
}

.acquaintance__profession {
   position: relative;
   font-weight: 400;
font-size: 16px;
color: var(--black);
}

.acquaintance__profession::after {
   content: url(../images/yellow-triangle.svg);
   position: absolute;
   bottom: -155px;
   right: -80px;
}

.acquaintance__list {
margin-bottom: 66px;
}

.acquaintance__item {
   position: relative;
   padding-left: 37px;
   margin-bottom: 16px;
   font-weight: 400;
   font-size: 16px;
   line-height: 131%;
   letter-spacing: 0.02em;
   color: var(--black);
}

.acquaintance__item::before {
   content:'' ;
   position: absolute;
   background-image: url(../images/green-vector.svg);
   width: 24px;
   height: 25px;
   left: 0;
}


@media (max-width: 1366px) {
.acquaintance__right-content::after {
   top: 91%;
   right: 20px;
}
.acquaintance__profession::after {
   display: none;
}

.acquaintance__right-content::before {
   right: 0;
}

.acquaintance__left-content::before {
   left: 0;
}
}

@media (max-width: 1024px) {
   .acquaintance__profession::after {
      right: 0;
   }
   .acquaintance__content {
      flex-direction: column;
   }

   .acquaintance__subtitle {
      margin-bottom: 60px;
   }

   .acquaintance__left-content::before {
      left: 100%;
      transform: scale(0.8);
   }
   .acquaintance__title::before {
      bottom: -234px;
      transform: scale(0.8);
   }

   .acquaintance__title::after {
      transform: scale(0.8);

   }

   .acquaintance__left-content::after {
      transform: scale(0.8);
      bottom: -85%;
      left: -5%;
   }

   .acquaintance__right-content::before {
      right: -33px;
      transform: scale(0.8);

   }
   .acquaintance__right-content::after {
      transform: scale(0.8);
   }
   .acquaintance__span-img {
      position: absolute;
      background-image: url(../images/magic_1.svg);
      background-repeat: no-repeat;
      background-size: cover;
      width: 163px;
      height: 170px;
      left: 58%;
      bottom: 5%;
   }
}

@media (max-width: 768px) {
   .acquaintance__left-content::before {
      left: 85%;
      transform: scale(0.5);
   }

   .acquaintance__title::before {
      bottom: -140px;
      transform: scale(0.5);
   }

   .acquaintance__left-content::after,
   .acquaintance__right-content::before{
      display: none;
   }
}

@media (max-width: 767px) {
.acquaintance {
   padding-top: 115px;
   padding-bottom: 230px;
}

   .acquaintance__photo{
      max-width: 320px;
   }
   .acquaintance__subtitle {
      margin-bottom: 30px;
   }

   .acquaintance__span-img {
      width: 96px;
      height: 100px;
   }
   .acquaintance__title-right {
      font-size: 20px;
   }

}