.format {
   position: relative;
   background-color: #ffbc9d;
   padding-top: 100px;
   padding-bottom: 100px;
   margin-bottom: 300px;
   margin-top: 200px;
   background-color: #ffbc9d;
}
.format::before{
content: "";
position: absolute;
background-image: url(../images/faq-top.webp);
background-repeat: no-repeat;
background-size: cover;
width: 100%;
height: 300px;
top: -260px;
}

.format::after{
   content: "";
   position: absolute;
   background-image: url(../images/format-union-bottom.webp);
   background-repeat: no-repeat;
   background-position: bottom center;
   width: 100%;
   background-size: cover;
   height: 300px;
   bottom: -298px;
}

.format__title {
   position: relative;
}

.format__title::after {
   content: url(../images/red-white.svg);
   position: absolute;
   right: 250px;
   top: -70px;
}

.format__img-pillows{
   position: absolute;
   top: -8%;
   left: 15%;
   max-height: 230px;
   max-width: 230px;
}
.format__img-child{
   position: absolute;
   top: 32%;
   left: 1%;
   max-height: 230px;
   max-width: 230px;
}

.format__images {
   position: absolute;
   bottom: 0;
   right: 0;
}
.format__img-hare{
   position: relative;
   max-height: 230px;
   max-width: 230px;
}

.format__img-ears {
position: absolute;
top: -78%;
    left: -141px;
}

.format__subtitle {
   position: relative;
}

.format__subtitle::after {
   content: url(../images/wavy-line-white.svg);
   position: absolute;
   bottom: -22px;
   left: 49%;
}

.format__subtitle::before {
   content: url(../images/yellow-red-white.svg);
   position: absolute;
   top: -130px;
   left: -40px;
   z-index: 10;
}

.format__descr {
   position: relative;
   margin: auto;
   margin-top:132px;
   max-width: 1000px;
   position: relative;
   text-align: center;
   font-size: 25px;
   line-height: 130%;
   letter-spacing: 0.02em;
   color: #000;
   font-weight: 300;
}

.format__descr::after {
   content: url(../images/white-triangle.svg);
   position: absolute;
   bottom: -150px;
   right: -137px;
   z-index: 10;
}

.format__descr::before {
   content: "";
   background-image: url(../images/format-present.svg);
   background-repeat: no-repeat;
   position: absolute;
   background-size: cover;
   width: 120px;
   height:200px ;
   bottom: -120%;
   left: -10%;
   z-index: 10;
}

@media (max-width: 1366px) {

   .format {
      padding-bottom: 0;
   }

   .format::before {
      background-size: cover;
   }
   .format__img-pillows {
      top: -28%;
      left: 4%;
   }

   .format__img-child {
      top: 26%;
      left: -5%;
   }

  .format__title::after {
   content: url(../images/red-white.svg);
   position: absolute;
   right: 144px;
   top: -120px;
   transform: scale(.8);

}

   .format__subtitle::before {
      top: 13px;
      left: 208px;
      transform: scale(0.8);
}

.format__img-hare {
   right: -53px;
   bottom: -30px;
   z-index: 10;
}

.format__descr {
   max-width: 650px;
}

.format__descr::before {
   bottom: -75%;
}
.format__img-hare {
   right: 5px;
}
}

@media (max-width: 1024px) {

   .format {
      margin-top: 270px;
      padding-top: 0;
   }

   .format__img-ears {
      width: 128px;
      height: 129px;
      transform: rotate(20deg);
      top: -102%;
    left: -39px;
      z-index: 1;
   }

   .format__title::after {
      right: 441px;
      top: -70px;
      transform: scale(.5);
  }
   .format__subtitle::after {
transform: scale(0.5);
      left: 46%;
  }
   .format__descr{
      margin-top: 65px;
     max-width: 400px;
     font-size: 22px;
  }
  .format__img-pillows,
  .format__img-child,
  .format__img-hare {
   max-width: 160px;
  }

  .format__subtitle::before {
   display: none;
  }

  .format__descr::before {
   width: 104px;
   height: 164px;
   bottom: -40%;
   left: -25%;
  }

  .format__descr::after {
   top: -137%;
   right: -50px;
   transform: scale(0.5);
}

  .format__img-pillows {
   left: -4%;
   top: -25%;
  }

  .format__img-child {
   top: 37%;
   left: -2%;
}

.format__img-hare {
   bottom: 40px;
}
 
  }

  @media (max-width: 767px) {
   .format{
      padding-top: 50px;
      margin-top: 150px;
      margin-bottom: 200px;
   }
   .format::before {
      height: 150px;
      top: -149px;
   }
   
   .format__img-pillows,
   .format__img-child,
   .format__img-hare {
    max-width: 99px;
   }

   .format__img-pillows {
      top: -15%;
   }
   .format__subtitle::before {
      content: "";
      position: absolute;
      left: 300px;
      top: -130px;
      background-image: url(../images/format-present-mob.svg);
      background-repeat: no-repeat;
      background-size: cover;
      width: 67px;
      height: 89px;
      display: block;
   }

   .format__subtitle::after {
      left: 37%;
  }

   .format__descr::before {
      display: none;
   }

   .format__title::after {
      right: -150px;
  }

  .format__img-child {
   top: 100%;
   z-index: 10;
  }

  .format__img-hare {
   bottom: -125px;
   right: 54px;
  }

  .format__img-ears {
   width: 66px;
   height: 67px;
   transform: rotate(60deg);
   top: 50px;
   left: -20px;
  }

  .format__title::after {
   display: none;
  }
  .format__descr{
   margin-top: 30px;
   font-size: 16px;
   max-width: 235px;
  }
  .format__descr::after{
   display: none;
  }
  }



  @media (max-width: 370px) {
   .format__subtitle::before{
      left: 220px;
   }
}


