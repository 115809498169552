.reviews {
   padding-top: 111px;
   overflow: hidden;
}

.reviews__title {
   position: relative;
   margin-bottom: 130px;
}

.reviews__title::before {
   content: url(../images/wavy-line-green.svg);
   position: absolute;
   bottom: -30px;
   right: 28%;
}

.reviews__title::after {
   content: url(../images/yellow-triangle.svg);
   position: absolute;
   bottom: 0;
   left: 125px;
}

.reviews__stars {
   position: relative;
   margin-bottom: 210px;
   gap: 50px;
   justify-content: center;
}
.reviews__stars a {
   transition: opacity 0.5s;
}
.reviews__stars a:hover {
   opacity: 0.6;
}

.reviews__stars::after {
   content: url(../images/blue-red-yellow.svg);
   position: absolute;
   bottom: -100px;
   right: 170px;
}

.reviews__img3 {
   align-items: center;
}

.reviews__photo img {
   border: 5px solid var(--main-color);
   border-radius: 13px;
   box-shadow: 0px 0px 27px 0px rgba(67, 82, 21, 0.25);
}

.reviews__photo{
   position: relative;
   min-height: 1000px;
}

.reviews__images1 {
   position: absolute;
   right: -15%;
   top: -4%;
   z-index: 5;
   transition: transform 2s;
}

#parallax.animate .reviews__images1 {
   transform: translateX(50px);
 }

.reviews__images2 {
position: absolute;
   right: -50px;
   top: 35%;
   z-index: 10;
   transition: transform 2s;
}

#parallax.animate .reviews__images2 {
   transform: translateX(-50px);
 }

.reviews__images3 {
   position: absolute;
   right: 13%;
   top: 25%;
   z-index: 10;
   transition: transform 2s;
}

#parallax.animate .reviews__images3 {
   transform: translateX(50px);
 }

.reviews__images4 {
   position: absolute;
   right: 130px;
   top: -10%;
}

.reviews__images5 {
   position: absolute;
   left: 46%;
   top: 01%;
z-index: 1;
transition: transform 2s;
}

#parallax.animate .reviews__images5 {
   transform: translateX(-50px);
 }

.reviews__images6 {
   position: absolute;
left: 50%;
    bottom: 24%;
    z-index: 10;
    transition: transform 2s;
}
.reviews__images7 {
   position: absolute;
   top: -6%;
   left: 26%;
   transition: transform 2s;
}
.reviews__images8 {
   position: absolute;
   left: 8%;
   transition: transform 2s;
}

#parallax.animate .reviews__images8 {
   transform: translateX(-50px);
 }

 #parallax.animate .reviews__images12 {
   transform: translateX(50px);
 }
 #parallax.animate .reviews__images6 {
   transform: translateX(-20px);
 }


.reviews__images9 {
   position: absolute;
left: -14%;
top: -8%;
transition: transform 2s;
}

#parallax.animate .reviews__images9 {
   transform: translateX(50px);
 }

.reviews__images10 {
   position: absolute;
   left: 20%;
   top: 39%;
   transition: transform 2s;
}

#parallax.animate .reviews__images10 {
   transform: translateX(50px);
 }
.reviews__images11 {
   position: absolute;
   left:-3%;
   top: 27%;
   transition: transform 2s;
}

.reviews__images12 {
   position: absolute;
   right: 351px;
   top: -8%;
   transition: transform 2s;
}

.reviews__images13 {
   position: absolute;
   left: 465px;
   top: 46%;
   border: none;
   z-index: -1;
}

.reviews__images13 img {
   border: none;

}


#parallax.animate .reviews__images11 {
   transform: translateX(-50px);
 }

 .reviews__swiper-container {
   display: none
 }

 /* мобильная версия */

 .reviews__photo-mob {
   margin-bottom: 25px;
  }

  .reviews__images1 img {
   max-height: 635px;
  }
  
  .reviews__images2 img {
   max-height: 463px;
  }
  .reviews__images3 img {
   max-height: 463px;
  }
  .reviews__images4 img {
   max-height: 370px;
  }
  .reviews__images5 img {
   max-height: 463px;
  }
  .reviews__images6 img {
   max-height: 450px;
  }
  .reviews__images7 img {
   max-height: 512px;
  }
  .reviews__images8 img {
   max-height: 512px;
  }
  .reviews__images9 img {
   max-height: 635px;
  }
  .reviews__images10 img {
   max-height: 347px;
  }
  .reviews__images11 img {
   max-height: 463px;
  }
  .reviews__images12 img {
   max-height: 463px;
  }

  .reviews-button-next {
   position: absolute;
   top: 50%;
   right: 10%;
   transform: translateY(-50%);
  }
.reviews-button-prev{
   position: absolute;
   top: 50%;
   left: 10%;
   transform: translateY(-50%);
}
.reviews-button-prev.swiper-button-disabled,
.reviews-button-next.swiper-button-disabled {
   opacity: 0.5;
}
 


 @media (max-width: 1024px) {
   .reviews {
      padding: 0 20px;
   }

   .reviews__photo {
      min-height: 650px;
   }
   .reviews__images1 {
      right: 2%;
   }

   .reviews__images2 {
      right: -8%;
   }

   .reviews__images5 {
      left: 65%;
   }
 
   .reviews__images6 {
      bottom: 34%;
   }

   .reviews__images9 {
      left: -1%;
   }

   .reviews__images8 {
      left: 17%;
   }

   .reviews__images11 {
      left: 1%;
   }

   .reviews__title::after {
      transform: scale(0.8);
      bottom: 70px;
   }
   .reviews__title::before {
      transform: scale(0.8);
      right: 23%;
   }

   .reviews__stars::after {
      transform: scale(0.8);

   }
  }

  @media (max-width:1024px) {
   .reviews__images1 img {
      max-height: 345px;
     }
     
     .reviews__images2 img {
      max-height: 324px;
     }
     .reviews__images3 img {
      max-height: 263px;
     }
     .reviews__images4 img {
      max-height: 355px;
     }
     .reviews__images5 img {
      max-height: 359px;
     }
     .reviews__images6 img {
      max-height: 310px;
     }
     .reviews__images6 {
      bottom: 20%;
      left: 42%;
     }

     .reviews__images7 img {
      max-height: 250px;
     }
     .reviews__images7 {
      left: 10%;
     }

     .reviews__images8 img {
      max-height: 345px;
     }

     .reviews__images8 {
      left: 30%;
     }
     .reviews__images9 img {
      max-height: 250px;
     }
     .reviews__images9 {
      left: -70px;
     }
     .reviews__images10 img {
      max-height: 245px;
     }
     .reviews__images10 {
      left: 14%;
     }
     .reviews__images11 img {
      max-height: 350px;
     }
     .reviews__images12 img {
      max-height: 340px;
     }

     .reviews__images13 {
      display: none;
     }
     .reviews__stars {
      margin-bottom: 150px;
     }
   
  }

  @media (max-width: 768px) {
   .reviews__title::before {
      right: 11%;
   }
  }



  @media (max-width: 767px) {
.reviews__photo {
   display: none;
}
.reviews__title {
   margin-bottom: 70px;
}
.reviews__swiper-container {
   display: block;
}

.reviews__images1,
.reviews__images2,
.reviews__images3,
.reviews__images4,
.reviews__images5,
.reviews__images6,
.reviews__images7,
.reviews__images8,
.reviews__images9,
.reviews__images10,
.reviews__images11 {
   position: static;
   
}
.reviews__images1 img,
.reviews__images2 img,
.reviews__images3 img,
.reviews__images4 img,
.reviews__images5 img,
.reviews__images6 img,
.reviews__images7 img,
.reviews__images8 img,
.reviews__images9 img,
.reviews__images10 img,
.reviews__images11 img {
   margin: 0 auto;
   border: 5px solid var(--main-color);
   border-radius: 13px;
   /* box-shadow: 0px 0px 10px 0px #43521540; */
max-width: 186px;
   max-height: unset;
}

.reviews__title::before {
   transform: scale(0.5);
   right: -3%;
   bottom: -19px;
}

.reviews__stars::after {
   transform: scale(0.5);
}
  }

  @media (max-width: 400px) {
   .reviews__title::before {
      right: 27%;
   }
  }